<template>
  <sides-lip ref="SidesLip" @click="close()">
    <div slot="soltForm">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        size="small"
        ref="dataForm"
        label-width="160px"
        v-loading="dataLoading"
        style="width: 100%"
        element-loading-spinner="el-icon-loading"
      >
        <el-row>
          <el-col :span="19">
            <product-no-validation
              :factoryId="factoryId"
              :isBase="dataForm.isBase == 1"
              @productNoChanged="productNoChanged"
              :dataForm="dataForm"
              ref="productNoValidation"
            />
          </el-col>

          <el-col :span="5">
            <el-form-item label-width="0px">
              <template>
                <el-checkbox
                  v-model="isBaseCheck"
                  size="small"
                  @change="updateBaseCheck"
                  style="padding-left: 20px"
                  >{{ $t('set_as_base') }}</el-checkbox
                >
              </template>
            </el-form-item>
          </el-col>
        </el-row>

        <div style="display: flex">
          <div style="width: 80%">
            <el-form-item :label="$t('batch_number')" prop="productOrder">
              <el-input
                v-model="dataForm.productOrder"
                :placeholder="$t('batch_number')"
                size="small"
                style="width: 100%"
                maxlength="30"
              ></el-input>
            </el-form-item>

            <el-form-item :label="$t('product_name')" prop="productName">
              <el-input
                v-model="dataForm.productName"
                :placeholder="$t('product_name')"
                size="small"
                style="width: 100%"
                maxlength="30"
              ></el-input>
            </el-form-item>

            <el-form-item :label="$t('customer_name')" prop="customerName">
              <el-input
                v-model="dataForm.customerName"
                :placeholder="$t('customer_name')"
                size="small"
                style="width: 100%"
                maxlength="30"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('product_status')" prop="state">
              <span v-if="dataForm.state == '0'">{{
                $t('sampling_process')
              }}</span>
              <span v-if="dataForm.state == '1'">{{
                $t('sample_product')
              }}</span>
              <span v-if="dataForm.state == '2'">{{
                $t('production_product')
              }}</span>
            </el-form-item>
          </div>
          <div style="width: 30%; margin-top: 0px; margin-left: 20px">
            <el-form-item label="" prop="picUrl" label-width="10px">
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="handleUploadSuccess"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="dataForm.picUrl"
                  :src="dataForm.picUrl"
                  class="avatar"
                />
                <div
                  v-else-if="!dataForm.picUrl && dataForm.hex"
                  :style="{
                    backgroundColor: dataForm.hex,
                    height: '178px',
                    minWidth: '178px'
                  }"
                ></div>
                <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                  :title="$t('click_to_upload')"
                ></i>
              </el-upload>
            </el-form-item>
          </div>
        </div>
        <div style="display: flex">
          <div style="width: 68%">
            <el-form-item :label="$t('factory')" prop="makeFactory">
              <el-select
                v-model="dataForm.makeFactory"
                size="small"
                :placeholder="$t('select_factory')"
                style="width: 100%"
                disabled="disabled"
                @change="setFactoryId"
              >
                <el-option :label="$t('all')" value="-1"></el-option>
                <el-option
                  v-for="item in factoryList"
                  :key="item.factoryName"
                  :label="item.factoryName"
                  :value="item.factoryName"
                  size="small"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div style="display: flex">
          <div style="width: 100%">
            <el-form-item :label="$t('measure_lab_values')">
              <div>
                <read-device @read="dataReceived" />
                <inline-message ref="inlineMessage" style="margin-top: 10px" />
              </div>
            </el-form-item>
            <el-form-item>
              <span slot="label"
                ><label style="color: #fa3d1e">*</label> L*a*b*</span
              >
              <el-row>
                <el-col :span="5">
                  <el-form-item prop="lAve">
                    <el-input
                      v-model="dataForm.lAve"
                      placeholder="L*"
                      class="w150 mr10"
                      size="small"
                      @input="getHex()"
                      clearable
                      maxlength="30"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item prop="aAve">
                    <el-input
                      v-model="dataForm.aAve"
                      placeholder="a*"
                      class="w150 mr10"
                      size="small"
                      @input="getHex()"
                      clearable
                      maxlength="30"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item prop="bAve">
                    <el-input
                      v-model="dataForm.bAve"
                      placeholder="b*"
                      class="w150 mr10"
                      size="small"
                      @input="getHex()"
                      clearable
                      maxlength="30"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <!--二维码通过后台自动生成存库，前编辑产品编号保存，后台二维码会自动变化，不再前端做新增和编辑-->
          </div>
        </div>
        <el-form-item label="">
          <el-collapse @change="handleChange">
            <el-collapse-item>
              <span class="collapse-title" slot="title">{{
                $t('view_detailed_color')
              }}</span>
              <div>
                <ul class="productLAB">
                  <li>
                    <span class="title">{{ $t('multi-angle') }} L*A*B*</span>
                  </li>
                  <li>
                    <span class="title">{{ $t('angle') }}</span>
                    <span v-for="x in productLAB.title" class="w100">{{
                      x
                    }}</span>
                  </li>
                  <li v-for="(x, index) in productLAB.data">
                    <span class="title">{{ x }}</span>
                    <el-input
                      v-model="dataForm[`l${index + 1}`]"
                      placeholder="L*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm[`a${index + 1}`]"
                      placeholder="a*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm[`b${index + 1}`]"
                      placeholder="b*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                  </li>
                  <li v-if="olabShow">
                    <span class="title"
                      >{{ $t('integrating_sphere') }}L*A*B*</span
                    >
                  </li>
                  <li v-if="olabShow">
                    <span class="title">{{ $t('angle') }}</span>
                    <span v-for="x in productLAB.title" class="w100">{{
                      x
                    }}</span>
                  </li>
                  <li v-if="olabShow">
                    <span class="title" style="line-height: 1.2">
                      D65/10°
                      <br />
                      <span class="ptitle"
                        >SPIN({{ $t('includes_mirror_reflection') }})</span
                      >
                    </span>
                    <el-input
                      v-model="dataForm[`lo`]"
                      placeholder="L*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm[`ao`]"
                      placeholder="a*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm[`bo`]"
                      placeholder="b*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                  </li>
                </ul>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>

        <div style="display: flex">
          <div style="width: 70%">
            <el-form-item :label="$t('gloss_value')" prop="glossValue">
              <el-input
                v-model="dataForm.glossValue"
                :placeholder="$t('gloss_value')"
                size="small"
                @input="selectGlossInfo()"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </div>
          <div style="width: 30%">
            <el-form-item label="" prop="gloss" label-width="20px">
              <el-select
                v-model="dataForm.gloss"
                size="small"
                placeholder=""
                style="width: 100%"
                disabled="disabled"
              >
                <el-option
                  v-for="item in productGlossList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  size="small"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="">
          <el-collapse @change="handleChange">
            <el-collapse-item>
              <span class="collapse-title" slot="title">{{
                $t('view_standards')
              }}</span>
              <div style="display: flex">
                <div style="width: 60%">
                  <template v-for="(item, index) in glossList">
                    <p v-if="index % 2 == 0" class="ziti">
                      {{ localizeParam(item, 'label') }}
                    </p>
                  </template>
                </div>
                <div style="width: 60%">
                  <template v-for="(item, index) in glossList">
                    <p v-if="index % 2 == 1" class="ziti">
                      {{ localizeParam(item, 'label') }}
                    </p>
                  </template>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>

        <div class="ces-main">
          <search-form
            ref="form"
            size="mini"
            labelWidth="150px"
            :searchData="searchData"
            :searchForm="searchForm"
          ></search-form>
        </div>

        <el-form-item :label="$t('standard_color_chart')" prop="ralColorNo">
          <el-input
            v-model="dataForm.ralColorNo"
            :placeholder="$t('product_standard_color_chart')"
            size="small"
            style="width: 100%"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('product_description')" prop="description">
          <div style="margin: 5px 0"></div>
          <el-input
            type="textarea"
            :rows="5"
            :placeholder="$t('product_description')"
            v-model="dataForm.description"
            maxlength="150"
            show-word-limit
          >
          </el-input>
        </el-form-item>
        <div style="display: flex">
          <div style="width: 68%">
            <el-form-item :label="$t('product_label')" prop="productLabel">
              <el-select
                v-model="dataForm.productLabel"
                size="small"
                :placeholder="$t('enter_product_label')"
                style="width: 100%"
                multiple
                clearable
              >
                <el-option
                  v-for="item in labelList"
                  :key="item.productLabelId"
                  :label="item.labelName"
                  :value="item.productLabelId"
                  size="small"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div slot="soltFooter">
      <span slot="footer">
        <el-button @click="close()" size="small">{{ $t('cancel') }}</el-button>
        <el-button
          type="primary"
          @click="dataFormSubmit()"
          size="small"
          :disabled="dataLoading"
          >{{ $t('confirm') }}</el-button
        >
      </span>
    </div>
  </sides-lip>
</template>

<script>
import {
  getFactoryBrand,
  info,
  getFactory1,
  getBrand,
  getHex
} from '@/api/modules/product'
import Tinymce from '@/components/Tinymce'
import { getToken } from '@/utils/auth'
import SidesLip from '@/components/SidesLip'
import SearchForm from '@/components/form.vue'
import {
  getAttrAndValueListInfo,
  getGlossInfo,
  selectGlossInfo
} from '@/api/modules/productattr'
import {
  getProductAttrValue,
  selectChildAttr
} from '@/api/modules/productattrvalue'
import {
  selectProductAttrInfo,
  selectProductLabelInfo
} from '@/api/modules/productnorule'
import { selectLabelList } from '@/api/modules/productlabel'
import { getSecondsAgoMessage } from '../../util/i18n/time'
import InlineMessage from '../../components/inlineMessage.vue'
import ReadDevice from '../../components/readDevice.vue'
import ProductNoValidation from '../../components/productNoValidation.vue'

export default {
  components: {
    Tinymce,
    SidesLip,
    SearchForm,
    InlineMessage,
    ReadDevice,
    ProductNoValidation
  },
  data() {
    return {
      addMethod: undefined,
      updateMethod: undefined,
      searchForm: [
        //这里是渲染查询表单的表头和类型的数据
        // {type:'Input',label:'姓名',prop:'name', width:'180px',placeholder:'请输入姓名...'},
        // {type:'Select',label:'性别',prop:'sex',width:'180px',options:sexs,props:sexProps,change:row=>'',placeholder:'请选择性别...'},
      ],
      searchData: {
        //查询表单的对应的值
        // name:'',
        // sex:null,
      },

      dialogVisible: false,
      biaoZhun: false,
      showLAB: false,
      loading: false,
      olabShow: false,
      action: `${
        process.env.VUE_APP_BASE_API
      }/sys/oss/upload?token=${getToken()}`,
      visible: false,
      dataLoading: false,
      isBaseCheck: false,
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 0, // 设置定时器时间
      productLAB: {
        title: ['L*', 'a*', 'b*'],
        data: ['25°', '45°', '75°']
      },
      FactoryBrand: {},
      factoryId: '',
      isProductNoValid: true,
      dataForm: {
        id: 0,
        productNo: '',
        productMainNo: '',
        productComponentNo: '',
        productVersionNo: '',
        productOrder: '',
        productName: '',
        makeFactory: '',
        customerName: '',
        picUrl: '',
        qrUrl: '',
        ralColorNo: '',
        gloss: '',
        glossValue: '',
        hex: '',
        surface: '',
        colorSeries: '',
        productSeries: '',
        productPerformance: '',
        otherPerformance: '',
        productCategory: '',
        productHierarchy: '',
        industryId: '',
        industryId_child: '',
        factoryId: '',
        brandId: '',
        description: '',
        l1: '',
        a1: '',
        b1: '',
        l2: '',
        a2: '',
        b2: '',
        l3: '',
        a3: '',
        b3: '',
        lo: '',
        ao: '',
        bo: '',
        lAve: '',
        aAve: '',
        bAve: '',
        isOpen: '',
        delFlag: '',
        createBy: '',
        createDate: '',
        updateBy: '',
        updateDate: '',
        remarks: '',
        test: '0',
        state: '',
        isBase: '0',
        productLabel: []
      },
      performanceList: [],
      industryList: [],
      industryList_child: [],
      colorSeriesList: [],
      productCategoryList: [],
      otherPerformanceList: [],
      productSeriesList: [],
      productGlossList: [],
      productSurfaceList: [],
      productHierarchyList: [],
      factoryList: [],
      labelList: [],
      brandList: [],
      glossList: [],
      dataRule: {
        productNo: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('product_number') }),
            trigger: 'blur'
          }
        ],
        lAve: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('l_measurement') }),
            trigger: 'blur'
          }
        ],
        aAve: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('a_measurement') }),
            trigger: 'blur'
          }
        ],
        bAve: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('b_measurement') }),
            trigger: 'blur'
          }
        ],
        productCategory: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('product_category') }),
            trigger: 'blur'
          }
        ],
        surface: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('product_surface') }),
            trigger: 'blur'
          }
        ],
        glossValue: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('gloss') }),
            trigger: 'blur'
          }
        ]
      },
      productAttrId: '',
      attrValue: ''
    }
  },
  beforeDestroy() {
    window.clearInterval(this.timer)
    this.timer = null
    this.timerNum = 0
  },
  methods: {
    getProductAttrValue() {
      let params = {}
      getProductAttrValue(params).then(({ data }) => {
        if (data && data.code === 0) {
          for (var i = 0; i < data.list.length; i++) {
            if (data.list[i].productAttrType === '1') {
              //表面
              this.productSurfaceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '3') {
              //系列
              this.productSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '6') {
              //主题
              this.productHierarchyList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '2') {
              //色系
              this.colorSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '7') {
              //性能
              this.performanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '4') {
              //应用
              this.industryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '8') {
              //功能
              this.otherPerformanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '5') {
              //分类
              this.productCategoryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '9') {
              //光泽
              this.productGlossList = data.list[i].productAttrValue
            }
          }
        }
      })
    },
    getHex() {
      var params = {
        lab:
          this.dataForm.lAve +
          ',' +
          this.dataForm.aAve +
          ',' +
          this.dataForm.bAve
      }
      getHex(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.hex = data.hex
        }
      })
    },
    selectChildAttr() {
      let params = {
        attrValueId: this.dataForm.industryId
      }
      selectChildAttr(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.industryList_child = data.list
        } else {
          this.industryList_child = []
        }
        this.dataForm.industryId_child = ''
      })
    },
    handleChange(val) {
      console.log(val)
    },
    //LAB值按钮隐藏显示
    isShowLAB() {
      this.showLAB = !this.showLAB
    },
    isBiaoZhun() {
      this.biaoZhun = !this.biaoZhun
    },

    //根据光泽测试值显示光泽范围名称
    selectGlossInfo() {
      this.dataForm.glossValue = this.dataForm.glossValue.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
      this.dataForm.glossValue = this.dataForm.glossValue.replace(
        /\.{2,}/g,
        '.'
      ) //只保留第一个. 清除多余的
      this.dataForm.glossValue = this.dataForm.glossValue
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
      this.dataForm.glossValue = this.dataForm.glossValue.replace(
        /^(\-)*(\d+)\.(\d).*$/,
        '$1$2.$3'
      ) //只能输入两个小数
      if (
        this.dataForm.glossValue.indexOf('.') < 0 &&
        this.dataForm.glossValue != ''
      ) {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        this.dataForm.glossValue = parseFloat(this.dataForm.glossValue)
      }
      // this.$set(this.dataForm.glossValue,e)
      this.$set(this.searchData, '1-' + this.productAttrId, '')
      let params = {
        glossValue: this.dataForm.glossValue
          ? parseInt(this.dataForm.glossValue)
          : ''
      }
      selectGlossInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          console.log('name:' + data.name)
          this.dataForm.gloss = this.localizeParam(data.data, 'name')
          this.productAttrId = data.data.productAttrId
          this.attrValue = data.data.id
          this.$set(this.searchData, '1-' + this.productAttrId, this.attrValue)
        } else {
          this.dataForm.gloss = ''
        }
      })
    },

    // 查询光泽值范围
    getGlossInfo() {
      let params = {}
      getGlossInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.glossList = data.data[0].options
        } else {
          this.glossList = ''
        }
      })
    },
    getResultMap() {
      this.getAttrResultMap()
      this.getLabelResultMap()
    },
    getLabelResultMap() {
      let params = {
        brandId: this.dataForm.brandId,
        productNo: this.dataForm.productNo,
        productLabel: this.dataForm.productLabel
      }
      selectProductLabelInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          console.log('data.labelList:' + data.labelList)
          this.dataForm.productLabel = data.labelList
        }
      })
    },
    getAttrResultMap() {
      let params = {
        brandId: this.dataForm.brandId,
        productNo: this.dataForm.productNo,
        searchData: this.searchData
      }
      selectProductAttrInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.searchData = data.resultMap
        }
      })
    },
    dataReceived(data) {
      // 收到了设备数据
      this.dataForm.hex = data.hex
      this.dataForm.picUrl = data.picUrl
      this.dataForm.lAve = data.lAve
      this.dataForm.aAve = data.aAve
      this.dataForm.bAve = data.bAve
      this.dataForm.l1 = data.l1
      this.dataForm.a1 = data.a1
      this.dataForm.b1 = data.b1
      this.dataForm.l2 = data.l2
      this.dataForm.a2 = data.a2
      this.dataForm.b2 = data.b2
      this.dataForm.l3 = data.l3
      this.dataForm.a3 = data.a3
      this.dataForm.b3 = data.b3

      if (data.secondsAgo) {
        this.$refs.inlineMessage.show(
          this.$t('read_success', {
            x: getSecondsAgoMessage(data.secondsAgo)
          })
        )
      }
      this.$forceUpdate()
    },
    getAttrAndValueListInfo: function () {
      this.searchData = {}
      this.searchForm = []
      this.tableCols = []
      var params = {
        type: 'detail',
        isUniversal: '0'
      }
      getAttrAndValueListInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          data.data.forEach((e) => {
            var obj = { type: '', label: '', width: '180px' }
            if (e.type == '1') {
              // 如果是单选
              obj.type = 'Select'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.label = e.value
              obj.isUniversal = e.isUniversal
            }
            if (e.type == '2') {
              obj.type = 'SelectMultiple'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
              obj.isUniversal = e.isUniversal
            }
            if (e.type == '3') {
              obj.type = 'Cascader'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
              obj.isUniversal = e.isUniversal
            }
            obj.label = this.localizeParam(e, 'label')
            this.$set(this.searchData, e.productAttrId, e.value)
            this.searchForm.push(obj)
          })
        } else {
          this.$message.error(data.msg)
          this.loading2 = false
        }
      })
    },

    init(id, state, isCopy, addUpdateMethods) {
      this.addMethod = addUpdateMethods[0]
      this.updateMethod = addUpdateMethods[1]
      this.getAttrAndValueListInfo()
      this.getGlossInfo()
      //进来就调用获取所有下拉列表
      this.isCopy = isCopy
      this.dataForm.state = state
      console.log(this.dataForm.state)
      this.dataForm.id = id || 0
      var titleMessage = this.dataForm.id
        ? this.$t('edit')
        : this.$t('new_x', { x: this.$t('product').toLowerCase() })
      titleMessage = this.isCopy ? this.$t('copy') : titleMessage
      this.$refs.SidesLip.init(titleMessage, '900px', 'block')
      this.getFactoryBrandList()
      this.getProductAttrValue()
      this.getFactoryList()
      this.getBrandList()
      this.getLabelList()
      this.loading = false
      this.visible = true
      this.$nextTick(() => {
        //清除缓存数据
        this.$refs['dataForm'].resetFields()
        this.dataForm.lAve = ''
        this.dataForm.aAve = ''
        this.dataForm.bAve = ''
        this.dataForm.l1 = ''
        this.dataForm.a1 = ''
        this.dataForm.b1 = ''
        this.dataForm.l2 = ''
        this.dataForm.a2 = ''
        this.dataForm.b2 = ''
        this.dataForm.l3 = ''
        this.dataForm.a3 = ''
        this.dataForm.b3 = ''
        this.dataForm.hex = ''
        this.dataForm.productNo = ''
        this.dataForm.productMainNo = ''
        this.dataForm.productComponentNo = ''
        this.dataForm.productVersionNo = ''
        this.dataForm.isBase = '0'
        this.isBaseCheck = false
        if (this.dataForm.id) {
          info({ id: this.dataForm.id }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.productOrder = data.product.productOrder
              this.dataForm.productNo = data.product.productNo
              this.dataForm.productMainNo = data.product.productMainNo
              this.dataForm.productComponentNo = data.product.productComponentNo
              this.dataForm.productVersionNo = data.product.productVersionNo
              this.dataForm.productName = data.product.productName
              if (isCopy) {
                this.dataForm.productNo += '-Copy'
              }
              this.dataForm.makeFactory = data.product.makeFactory
              this.dataForm.customerName = data.product.customerName
              this.dataForm.picUrl = data.product.picUrl
              this.dataForm.qrUrl = data.product.qrUrl
              this.dataForm.ralColorNo = data.product.ralColorNo
              this.dataForm.gloss = data.product.gloss
              this.dataForm.glossValue = data.product.glossValue
              this.dataForm.hex = data.product.hex
              this.dataForm.surface = data.product.surface
              this.dataForm.colorSeries = data.product.colorSeries
              this.dataForm.productSeries = data.product.productSeries
              this.dataForm.productPerformance = data.product.productPerformance
              this.dataForm.otherPerformance = data.product.otherPerformance
              this.dataForm.productCategory = data.product.productCategory
              this.dataForm.productHierarchy = data.product.productHierarchy
              this.dataForm.industryId = data.product.industryId
              //获取
              this.selectChildAttr()
              this.dataForm.industryId_child = data.product.industryChildId
              this.dataForm.factoryId = data.product.factoryId
              this.dataForm.brandId = data.product.brandId
              this.dataForm.description = data.product.description
              this.dataForm.l1 = data.product.l1
              this.dataForm.a1 = data.product.a1
              this.dataForm.b1 = data.product.b1
              this.dataForm.l2 = data.product.l2
              this.dataForm.a2 = data.product.a2
              this.dataForm.b2 = data.product.b2
              this.dataForm.l3 = data.product.l3
              this.dataForm.a3 = data.product.a3
              this.dataForm.b3 = data.product.b3
              this.dataForm.lAve = data.product.lAve
              this.dataForm.aAve = data.product.aAve
              this.dataForm.bAve = data.product.bAve
              if (null != data.productLab) {
                this.dataForm.lo = data.productLab.l
                this.dataForm.ao = data.productLab.a
                this.dataForm.bo = data.productLab.b
                this.olabShow = true
              } else {
                this.olabShow = false
              }
              this.dataForm.test = data.product.test
              this.dataForm.state = data.product.state
              this.dataForm.productLabel = data.product.labelList
              this.dataForm.isBase = data.product.isBase
              if (data.product.isBase == 1) {
                this.isBaseCheck = true
              } else {
                this.isBaseCheck = false
              }
              // 获取属性
              this.searchData = data.resultMap
              if (this.dataForm.productNo && !this.dataForm.productMainNo) {
                this.dataForm.productMainNo = this.dataForm.productNo
              }
              this.setFactoryId()
            }
          })
        }
      })
    },
    close() {
      this.performanceList = []
      //清除缓存数据
      this.$refs['dataForm'].resetFields()
      this.$refs.SidesLip.sideClose()
    },

    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        var verification = this.$refs.form.verification()
        this.$refs.productNoValidation.validate()
        if (valid && this.isProductNoValid) {
          if (verification) {
            this.$message.warning(this.$t('e706', { x: verification.label }))
          } else {
            this.dataLoading = true
            var isBase = '0'
            if (this.isBaseCheck) {
              isBase = '1'
            }
            if (this.isCopy) {
              // 如果在复制，忘记原来的id便创建新的产品
              this.dataForm.id = undefined
            }
            var params = {
              searchData: this.searchData,
              id: this.dataForm.id || undefined,
              labelList: this.dataForm.productLabel,
              productNo: this.dataForm.productNo,
              productMainNo: this.dataForm.productMainNo,
              productComponentNo: this.dataForm.productComponentNo,
              productVersionNo: this.dataForm.productVersionNo,
              productOrder: this.dataForm.productOrder,
              productName: this.dataForm.productName,
              makeFactory: this.dataForm.makeFactory,
              customerName: this.dataForm.customerName,
              picUrl: this.dataForm.picUrl,
              qrUrl: this.dataForm.qrUrl,
              ralColorNo: this.dataForm.ralColorNo,
              gloss: this.dataForm.gloss,
              glossValue: this.dataForm.glossValue,
              surface: this.dataForm.surface,
              colorSeries: this.dataForm.colorSeries,
              productSeries: this.dataForm.productSeries,
              productPerformance: this.dataForm.productPerformance,
              otherPerformance: this.dataForm.otherPerformance,
              productCategory: this.dataForm.productCategory,
              productHierarchy: this.dataForm.productHierarchy,
              industryId: this.dataForm.industryId,
              industryId_child: this.dataForm.industryId_child,
              factoryId: '',
              brandId: '',
              description: this.dataForm.description,
              l1: this.dataForm.l1,
              a1: this.dataForm.a1,
              b1: this.dataForm.b1,
              l2: this.dataForm.l2,
              a2: this.dataForm.a2,
              b2: this.dataForm.b2,
              l3: this.dataForm.l3,
              a3: this.dataForm.a3,
              b3: this.dataForm.b3,
              lAve: this.dataForm.lAve,
              aAve: this.dataForm.aAve,
              bAve: this.dataForm.bAve,
              test: this.dataForm.test,
              state: this.dataForm.state,
              isBase: isBase
            }
            // console.log(this.dataForm.id)
            var tick = !this.dataForm.id
              ? this.addMethod(params)
              : this.updateMethod(params)
            tick.then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: this.$t('success'),
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    // this.visible = false;
                    this.dataLoading = false
                    this.$emit('refreshDataList')
                    this.$refs.SidesLip.sideClose()
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false
              }
            })
          }
        }
      })
    },
    //上传产品图处理
    handleUploadSuccess(res, file, type) {
      this.dataForm.picUrl = res.data.fileUrl
    },
    //上传前对图片大小及格式进行判断
    beforeUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/bmp' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        this.$message.error('上传图片格式不支持!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 8MB!')
      }
      return isJPG && isLt2M
    },
    //获取工厂和品牌
    getFactoryBrandList() {
      let params = {}
      getFactoryBrand(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.FactoryBrand = data.factoryBrand
        } else {
          this.FactoryBrand = {}
        }
      })
    },
    //获取工厂列表
    getFactoryList() {
      let params = {}
      getFactory1(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.factoryList = data.list
          if (
            data.factoryId != null &&
            data.factoryId != undefined &&
            this.factoryList.length > 0
          ) {
            this.dataForm.makeFactory = this.factoryList[0].factoryName
            this.setFactoryId()
          }
        } else {
          this.factoryList = []
        }
      })
    },
    //获取品牌列表
    getBrandList() {
      let params = {}
      getBrand(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.brandList = data.list
        } else {
          this.brandList = []
        }
      })
    },
    //获取标签列表
    getLabelList() {
      let params = {
        all: '1'
      }
      selectLabelList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.labelList = data.labelList
        } else {
          this.labelList = []
        }
      })
    },
    setFactoryId() {
      this.factoryId = this.factoryList.find(
        (factory) => factory.factoryName == this.dataForm.makeFactory
      ).id
    },
    updateBaseCheck() {
      this.dataForm.isBase = this.isBaseCheck ? '1' : '0'
    },
    productNoChanged(data) {
      console.log(data)
      this.getResultMap()
      this.isProductNoValid = data.isProductNoValid
      this.dataForm.productNo = data.productNo
      this.dataForm.productMainNo = data.productMainNo
      this.dataForm.productComponentNo = data.productComponentNo
      this.dataForm.productVersionNo = data.productVersionNo
    }
  }
}
</script>
<style lang="scss">
.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: 20px;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
}
</style>
<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 178px;
  text-align: center;
}

.ziti {
  font-size: 12px;
  margin: 0px;
  line-height: 25px;
}

.avatar {
  width: 178px;
  max-height: 178px;
  display: block;
}

.productLAB {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  margin-left: -50px;
  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;
    .title {
      display: inline-block;
      width: 160px;
      text-align: center;
      color: #606266;
      font-size: 700;
    }
    .ptitle {
      display: inline-block;
      width: 160px;
      text-align: center;
      font-size: 10px;
    }
  }
}

.w100 {
  width: 100px;
}
.w150 {
  width: 115px;
}
.mr10 {
  margin-right: 5px;
}
.collapse-title {
  flex: 1 0 90%;
  color: #4ab7bd;
  font-weight: bolder;
  text-align: center;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 18px;
}

.el-form-item__label {
  width: auto;
}
</style>
