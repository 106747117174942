<template>
  <div v-loading="loading" :style="'height:' + height">
    <el-alert :title="title" :closable="false" type="error" effect="dark">
    </el-alert>
    <iframe
      :src="url"
      frameborder="no"
      style="width: 100%; height: 100%"
      scrolling="auto"
    />
  </div>
</template>
<script>
import { getToken, getUser } from '@/utils/auth'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      height: document.documentElement.clientHeight - 124 + 'px;',
      loading: true,
      baseUrl: '',
      url: '',
      title: '您正在访问[' + getUser().brandName + ']私有服务器上的功能和数据'
    }
  },
  computed: {
        ...mapGetters(['userDevice'])
  },
  watch: {
    $route: {
      handler() {
        this.url = this.getUrl(this.$route.name)
      },
      deep: true
    },
    'userDevice.id'(newval, olval) {
      this.updateDeviceInfo()
    },
    'userDevice.connectionType'(newval, olval) {
      this.updateDeviceInfo()
    }
  },
  mounted: function () {
    this.url = this.getUrl(this.$route.meta.path)
    setTimeout(() => {
      this.loading = false
    }, 300)
    const that = this
    window.onresize = function temp() {
      that.height = document.documentElement.clientHeight - 124 + 'px;'
    }
  },
  methods: {
    getUrl(url) {
      this.baseUrl = url
      const index = url.indexOf('?')
      if (index != -1) {
        url += '&'
      } else {
        url += '?'
      }
      url +=
        'token=' +
        getToken() +
        '&deviceId=' +
        this.$store.getters.userDevice.id +
        '&connectionType=' +
        this.$store.getters.userDevice.connectionType
      return url
    },
    updateDeviceInfo() {
      if (this.baseUrl) {
        this.url = this.getUrl(this.baseUrl)
      }
    }
  }
}
</script>
