<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item>
        <el-select
          v-model="dataForm.userId"
          filterable
          :placeholder="$t('employee_name')"
          clearable
          :disabled="!isAuth('search:searchhistory:viewothers')"
        >
          <el-option
            v-for="item in userList"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="operation">
        <el-select
          v-model="dataForm.operation"
          :placeholder="$t('match_results')"
          clearable
        >
          <el-option
            v-for="item in stateArry"
            :key="item.stateKey"
            :label="item.stateValue"
            :value="item.stateKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="matchType">
        <el-select
          v-model="dataForm.matchType"
          :placeholder="$t('match_type')"
          clearable
        >
          <el-option :label="$t('default')" value="1"></el-option>
          <el-option :label="$t('manual')" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="remarks">
        <el-input
          v-model="dataForm.remarks"
          :placeholder="$t('notes')"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="deNum">
        <el-input
          v-model="dataForm.deNum"
          :placeholder="$t('results_with_e_lt_1')"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="daterange">
        <div class="block">
          <el-date-picker
            v-model="dataForm.searchDate"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            :start-placeholder="$t('search_from')"
            :end-placeholder="$t('search_to')"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">{{
          $t('search')
        }}</el-button>
        <el-button type="primary" @click="exportExcel()" size="small">
          {{ $t('export') }}
        </el-button>
        <el-button @click="reset('dataForm')" size="small">{{
          $t('clear')
        }}</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      :row-class-name="tableRowClassName"
      style="width: 100%"
    >
      <el-table-column
        prop="username"
        header-align="center"
        align="center"
        :label="$t('employee_name')"
      >
      </el-table-column>
      <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        :label="$t('notes')"
      >
      </el-table-column>
      <el-table-column
        width="280"
        prop="searchProductNo1"
        header-align="center"
        align="center"
        :render-header="renderHeader"
        :label="$t('search_results')"
      >
        <template slot-scope="scope">
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            :content="scope.row.searchProductNo1"
          >
          </el-popover>
          <div
            v-for="(item, index) in scope.row.searchProductNo00"
            :key="index"
            v-show="
              index < 5
            "
          >
            <span>{{ item.replace(/:/g, '，') }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="deNum4"
        header-align="center"
        align="center"
        :label="$t('result_summary')"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.deNum1 }}（ΔE*<0.5）</div>
          <div>{{ scope.row.deNum2 }}（0.5<ΔE*<=1）</div>
          <div>{{ scope.row.deNum3 }}（1<ΔE*<=2）</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="searchType"
        header-align="center"
        align="center"
        width="100"
        :label="$t('search_mode')"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.searchType == '0'">{{
            $t('scan_with_device')
          }}</span>
          <span v-if="scope.row.searchType == '1'">{{
            $t('search_by_color_chart')
          }}</span>
          <span v-if="scope.row.searchType == '2'">{{
            $t('search_by_color_picker')
          }}</span>
          <span v-if="scope.row.searchType == '3'">{{
            $t('search_by_number')
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        :label="$t('search_parameters')"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.brandName ? scope.row.brandName + '，\n' : ''
          }}</span>
          <span>{{
            scope.row.factoryName ? scope.row.factoryName + '，\n' : ''
          }}</span>
          <span>{{
            scope.row.searchparams ? scope.row.searchparams + '，\n' : ''
          }}</span>
          <span v-if="scope.row.state == '0'">{{
            $t('sampling_process')
          }}</span>
          <span v-if="scope.row.state == '1'">{{ $t('sample_product') }}</span>
          <span v-if="scope.row.state == '2'">{{ $t('production_product') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="operation"
        header-align="center"
        align="center"
        width="100"
        :label="$t('match_results')"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.operation == '1'">{{
            $t('no_match_given')
          }}</span>
          <span v-if="scope.row.operation == '2'">{{
            $t('perfect_match')
          }}</span>
          <span v-if="scope.row.operation == '4'">{{ $t('fair_match') }}</span>
          <span v-if="scope.row.operation == '3'">{{ $t('poor_match') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="matchType"
        header-align="center"
        align="center"
        width="100"
        :label="$t('match_results')"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.matchType == '1'">{{ $t('default') }}</span>
          <span v-if="scope.row.matchType == '2'">{{ $t('manual') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="searchDate"
        header-align="center"
        width="90"
        align="center"
        :label="$t('search_date')"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        :label="$t('action')"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >{{ $t('edit_match') }}</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            v-if="isAuth('search:searchhistory:delete')"
            >{{ $t('delete') }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import { list, del } from '@/api/modules/searchhistoryList'
import { userList } from '@/api/sys'
import AddOrUpdate from './add-or-update'
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      dataForm: {
        userId: this.isAuth('search:searchhistory:viewothers')
          ? ''
          : sessionStorage.getItem('userId'),
        matchType: '',
        deNum: '',
        operation: '',
        remarks: '',
        searchDate: [],
        daterange: []
      },
      stateArry: [
        { stateKey: '1', stateValue: this.$t('no_match_given') },
        { stateKey: '2', stateValue: this.$t('perfect_match') },
        { stateKey: '4', stateValue: this.$t('fair_match') },
        { stateKey: '3', stateValue: this.$t('poor_match') }
      ],
      dataList: [],
      userList: [
        {
          userId: this.isAuth('search:searchhistory:viewothers')
            ? ''
            : sessionStorage.getItem('userId'),
          name: this.isAuth('search:searchhistory:viewothers')
            ? ''
            : sessionStorage.getItem('userName')
        }
      ],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate
  },
  created() {
    this.getUserList()
  },
  //写个过滤器，保留小数点后两位
  filters: {
    numFilter(value) {
      let realVal = ''
      if (!isNaN(value) && value !== '') {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(2)
      } else {
        realVal = '--'
      }
      return realVal
    }
  },
  watch: {
    $route() {
      if (this.$route.name == 'searchhistoryList') {
        this.restPage()
      }
    }
  },
  methods: {
    getUserList() {
      var params = {
        page: 1,
        limit: 100
      }
      userList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.userList = data.page.list
        } else {
          this.userList = []
        }
        this.getDataList()
      })
    },
    //导出数据
    exportExcel() {
      var arrOrderTime = this.dataForm.searchDate
      var startDate = null
      var endDate = null
      if (arrOrderTime.length > 0) {
        startDate = arrOrderTime[0]
        endDate = arrOrderTime[1]
      }
      var params = {
        userId: this.dataForm.userId,
        matchType: this.dataForm.matchType,
        deNum: this.dataForm.deNum,
        operation: this.dataForm.operation,
        startDate: startDate,
        endDate: endDate
      }
      var url =
        `${process.env.VUE_APP_BASE_API}/searchHistory/searchhistory/exportExcel?userId=` +
        params.userId +
        '&deNum=' +
        params.deNum +
        '&matchType=' +
        params.matchType +
        '&operation=' +
        params.operation +
        '&startDate=' +
        params.startDate +
        '&endDate=' +
        params.endDate +
        `&token=${getToken()}`
      // 模拟创建元素实现
      var alink = document.createElement('a')
      alink.href = url
      alink.style = 'display:none'
      alink.click()
    },
    renderHeader(createElement, { column }) {
      const questionMark = createElement('i', {
        class: 'el-icon-question',
        attrs: {
          title: this.$t('search_result_format_description')
        }
      })
      const titleText = createElement(
        'span',
        {
          style: 'cursor: pointer',
          attrs: {
            title: this.$t('search_result_format_description')
          }
        },
        this.$t('search_results') + ' '
      )

      return createElement('div', [titleText, questionMark])
    },
    //状态不匹配，且数据不为空，就显示红色
    tableRowClassName({ row }) {
      if (row.operation == '3' && row.deNum1 > 0) {
        return 'warning-row'
      }
      if (row.operation == '3' && row.deNum2 > 0) {
        return 'warning-row'
      }
      return ''
    },

    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.dataForm.searchDate = []
      this.$refs.dataForm.resetFields()
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      var arrOrderTime = this.dataForm.searchDate
      var strOrderTime = ''
      var startDate = null
      var endDate = null
      if (arrOrderTime.length > 0) {
        startDate = arrOrderTime[0]
        endDate = arrOrderTime[1]
      }
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        userId: this.dataForm.userId,
        matchType: this.dataForm.matchType,
        remarks: this.dataForm.remarks,
        deNum: this.dataForm.deNum,
        operation: this.dataForm.operation,
        startDate: startDate,
        endDate: endDate
      }
      list(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          if (this.dataList != null && this.dataList.length > 0) {
            for (var i = 0; i < this.dataList.length; i++) {
              var list = []
              if (
                this.dataList[i].searchProductNo1 != undefined &&
                this.dataList[i].searchProductNo1 != ''
              ) {
                var s1 = this.dataList[i].searchProductNo1.split(',')
                this.dataList[i].searchProductNo11 = s1
                list = list.concat(this.dataList[i].searchProductNo11)
              }

              if (
                this.dataList[i].searchProductNo2 != undefined &&
                this.dataList[i].searchProductNo2 != ''
              ) {
                var s1 = this.dataList[i].searchProductNo2.split(',')
                this.dataList[i].searchProductNo22 = s1
                list = list.concat(this.dataList[i].searchProductNo22)
              }

              if (
                this.dataList[i].searchProductNo3 != undefined &&
                this.dataList[i].searchProductNo3 != ''
              ) {
                var s1 = this.dataList[i].searchProductNo3.split(',')
                this.dataList[i].searchProductNo33 = s1
                list = list.concat(this.dataList[i].searchProductNo33)
              }

              if (
                this.dataList[i].searchProductNo4 != undefined &&
                this.dataList[i].searchProductNo4 != ''
              ) {
                var s1 = this.dataList[i].searchProductNo4.split(',')
                this.dataList[i].searchProductNo44 = s1
                list = list.concat(this.dataList[i].searchProductNo44)
              }
              console.log(list)
              this.dataList[i].searchProductNo00 = list
            }
            // console.log(this.dataList)
            // var item = '08640024(base):0.358:90'
            // console.log(parseFloat(item.substring(item.indexOf('.'),item.length)))
          }
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 删除
    deleteHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id
          })
      this.$confirm(
        this.$t('delete_record'),
        this.$t('attention'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }
      ).then(() => {
        del(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: this.$t('success'),
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    }
  }
}
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
