<template>
  <el-dialog
    :title="this.title"
    :close-on-click-modal="false"
    @close="closeHandle"
    style="text-align: center"
    :visible.sync="visible"
    :append-to-body="true"
  >
    <el-form
      size="small"
      label-width="120px"
      v-loading="dataLoading"
      element-loading-spinner="el-icon-loading"
    >
      <div style="text-align: center; width: 100%; margin-bottom: 30px">
        <span style="margin-right: 10px">{{ $t('factory') }}</span>
        <el-select v-model="factoryId" size="small" :placeholder="$t('select_factory')">
          <el-option
            v-for="item in factoryList"
            :key="item.id"
            :label="item.factoryName"
            :value="item.id"
            size="small"
          ></el-option>
        </el-select>
      </div>
      <el-upload
        drag
        action="13"
        :http-request="uploadExcel"
        :before-upload="beforeUploadHandle"
        multiple
        :file-list="fileList"
        :show-file-list="false"
        :disabled="inProgressUpload"
        style="text-align: center"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">{{ $t('drag_or_click_to_upload') }}</div>
        <div class="el-upload__tip" slot="tip">
          {{ $t('only_excel_files_supported') }}
        </div>
      </el-upload>
    </el-form>

    <span v-if="uploadComplete || inProgressUpload" class="productall-uploaded">
      <span v-if="uploadComplete"
        ><i class="el-icon-success"></i>
        {{ $t('upload_complete') }} &nbsp;</span
      >
      <span v-else>{{ $t('previous_upload_pending') }} &nbsp;</span>
      <span v-if="isProductAll"
        >{{ $t('upload_process_navigate_history') }}
        <el-button
          size="small"
          @click="jumpPage()"
          class="btn"
          type="primary"
          style="margin-left: 8px"
          >{{ $t('review_import') }}</el-button
        ></span
      >
    </span>

    <div class="warning-message" v-for="(warningListItem, index) in warningList" :key="index">
      <i class="el-icon-warning"></i>
      <strong> {{ warningListItem.warningMessage }}</strong>
      <div v-if="!warningListItem.seeMoreVisible && warningListItem.items.length > 0">
        <el-button @click="seeMore(warningListItem)" type="text">{{
          $t('view_details')
        }}</el-button>
      </div>
      <div v-else class="details-box">
        <span v-for="(item, index) in warningListItem.items" :key="index"
          >{{ item }}<span v-if="index != warningListItem.items.length - 1">, </span>
        </span>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getFactory } from '@/api/modules/product'
import { hasInProgressUpload } from '@/api/modules/productImportRecord'
export default {
  data() {
    return {
      dataLoading: false,
      visible: false,
      successNum: 0,
      fileList: [],
      factoryList: [],
      factoryId: '',
      title: '',
      isProductAll: false,
      importMethod: undefined,
      inProgressUpload: false,
      uploadComplete: false,
      seeMoreVisible: false,
      warningList: []
    }
  },
  methods: {
    init(importMethod, title, isProductAll) {
      this.inProgressUpload = false
      this.uploadComplete = false
      this.importMethod = importMethod
      this.title = title
      this.isProductAll = isProductAll
      this.visible = true
      this.getFactoryList()
      this.warningMessage = ''
      ;(this.warningList = []), (this.seeMoreVisible = false)

      if (isProductAll) {
        this.checkHasInProgressUpload()
      }
    },
    checkHasInProgressUpload() {
      hasInProgressUpload().then(({ data }) => {
        if (data && data.code === 0) {
          this.inProgressUpload = data.hasInProgressUpload
        }
      })
    },
    //获取工厂列表
    getFactoryList() {
      let params = {
        brandId: ''
      }
      getFactory(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.factoryList = data.list
          this.factoryId = data.factoryId
        } else {
          this.factoryList = []
        }
      })
    },
    // 上传之前
    beforeUploadHandle(file) {
      const isExcel =
        file.name.toString().indexOf('.xls') > 0 ||
        file.name.toString().indexOf('.xlsx') > 0
      const isLt10M = file.size / 1024 / 1024 < 5
      if (!isExcel) {
        this.$message.error(this.$t('e710', { x: this.$t('file') }))
      }
      if (!isLt10M) {
        this.$message.error(this.$t('e727', { x: this.$t('file'), y: '5MB' }))
      }
      return isExcel && isLt10M
    },
    // 弹窗关闭时
    closeHandle() {
      this.fileList = []
      this.$emit('refreshDataList')
    },
    uploadExcel(item) {
      if (
        this.factoryId == '' ||
        this.factoryId == null ||
        this.factoryId == undefined
      ) {
        this.$message.error(this.$t('e706', { x: this.$t('factory') }))
        return
      }
      let importData = new FormData()
      importData.append('file', item.file)
      importData.append('factoryId', this.factoryId)

      let importMethod = this.importMethod
      importMethod(importData).then(({ data }) => {
        console.log(data)
        if (data && data.code === 0) {
          this.$message({
            message: this.$t('success'),
            type: 'success',
            duration: 2000
          })
          this.dataLoading = false
          this.uploadComplete = true

          if (data.warningList) {
            this.warningList = data.warningList
            this.warningList.forEach(warningItem => warningItem.seeMoreVisible = false)
          }
        } else {
          this.dataLoading = false
          return this.$alert(data.msg)
        }
      })
    },
    seeMore(item) {
      item.seeMoreVisible = true
      this.$forceUpdate()
    },
    jumpPage() {
      this.visible = false
      this.$router.push('/productImportRecord')
    }
  }
}

// 以前的积分球代码（路径目前（2022.12.02）不存在了）
// let importData = new FormData()
// importData.append('file', item.file)
// importData.append('factoryId', this.factoryId)
// importDataColorExcel(importData).then((res) => {
//   const content = res.data
//   console.log('res:' + res.toString())
//   // const blob = new Blob([content])
//   const blob = new Blob([content])
//   const fileName = '导入信息汇总.txt'
//   if ('download' in document.createElement('a')) {
//     // 非IE下载
//     const elink = document.createElement('a')
//     elink.download = fileName
//     elink.style.display = 'none'
//     elink.href = URL.createObjectURL(blob)
//     document.body.appendChild(elink)
//     elink.click()
//     URL.revokeObjectURL(elink.href) // 释放URL 对象
//     document.body.removeChild(elink)
//   } else {
//     // IE10+下载
//     navigator.msSaveBlob(blob, fileName)
//   }
// })
</script>
<style lang="scss" scoped>
.productall-uploaded {
  font-size: 14px;
  background-color: #e1f8d8;
  border: 1px solid #bbdaab;
  padding: 12px;
  margin-top: 20px;
  color: #67c23a;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  display: inline-block;
}
.warning-message {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 20px;
  width: 80%;
}
.details-box {
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  padding: 10px;
  height: 100px;
  margin: 5px;
  overflow-y: scroll;
  scrollbar-width: thin;
  word-break: normal;
  text-align: left;
}
</style>
