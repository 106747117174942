<template>
  <!--<el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">-->
  <sides-lip ref="SidesLip" @click="close()" >
    <div slot="soltForm">
      <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm"  label-width="150px"
       v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
      >
        <el-form-item :label="$t('assigned_brand')" prop="brandId">
          <el-select v-model="dataForm.brandId" clearable :placeholder="$t('请选择')" :disabled="dataForm.id">
            <el-option
                    v-for="item in dataForm.list"
                    :key="item.brandId"
                    :label="item.brandName"
                    :value="item.brandId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('factory_name')" prop="factoryName">
          <el-input v-model="dataForm.factoryName" :placeholder="$t('factory_name')"  size="small" maxlength="30"></el-input>
        </el-form-item>
        <el-form-item :label="$t('factory_logo')" prop="factoryLogo">
          <el-upload
                  class="avatar-uploader"
                 :action="action"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
            <img v-if="dataForm.factoryLogo" :src="dataForm.factoryLogo" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" :title="$t('click_to_upload')"></i>
          </el-upload>
        </el-form-item>
        <area-map :msgVal = "sendMsg" ref="areaMapMsg" v-if="sendMsg"></area-map>
        <el-form-item label="工厂实验室" prop="laboratory">
          <el-input v-model="dataForm.laboratory" placeholder="工厂实验室"  size="small" maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="实验室负责人" prop="leadMan">
          <el-input v-model="dataForm.leadMan" placeholder="实验室负责人"  size="small" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="工厂信息" prop="remarks">
            <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="工厂信息"
                    maxlength="150"
                    show-word-limit
                    v-model="dataForm.remarks"
            >
            </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="soltFooter">
      <span slot="footer" class="dialog-footer">
        <el-button @click="sideClose()" size="small">返回</el-button>
        <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
      </span>
    </div>
  </sides-lip>
<!--  </el-dialog>-->
</template>

<script>
  import {info,add,update,brandList} from '@/api/modules/factory';
  import {getToken} from '@/utils/auth';
  import Tinymce from '@/components/Tinymce';
  import AreaMap from './area-map';
  import SidesLip from "@/components/SidesLip";
  export default {
    components: {
      AreaMap,
      Tinymce,
      SidesLip
    },
    data () {
      return {
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
        sendMsg:null,
        dataForm: {
          id: '',
          factoryName: '',
          factoryLogo: '',
          lng: '',
          lat: '',
          laboratory: '',
          leadMan: '',
          remarks: '',
          list:[],
          brandId: ''
        },
        dataRule: {
          factoryName: [
            { required: true, message: '工厂名称不能为空', trigger: 'blur' }
          ],
          factoryLogo: [
            { required: true, message: '工厂LOGO不能为空', trigger: 'blur' }
          ],
          brandId: [
            { required: true, message: '品牌id不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.$refs.SidesLip.init(
                id ? "修改" : "新增",
                "700px",
                "block"
        );
        this.getBrandList();
        this.dataForm.id = id || 0
        this.visible = true;
        this.sendMsg = null;
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.sendMsg = data.dataForm =data.factory;
                if(!this.sendMsg.longitude){
                  this.sendMsg ={};
                }
                console.log(this.sendMsg);
              }else{
                this.sendMsg ={};
              }
              this.dataForm.factoryName = data.factory.factoryName
              this.dataForm.factoryLogo = data.factory.factoryLogo
              this.dataForm.brandId = data.factory.brandId
              this.dataForm.laboratory = data.factory.laboratory
              this.dataForm.leadMan = data.factory.leadMan
              this.dataForm.remarks = data.factory.remarks
              this.sendMsg.address = data.factory.address
              this.sendMsg.lng = data.factory.lng
              this.sendMsg.lat = data.factory.lat
            })
          }else{
            this.sendMsg ={};
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'factoryName': this.dataForm.factoryName,
              'factoryLogo': this.dataForm.factoryLogo,
              'brandId': this.dataForm.brandId,
              'address':this.$refs.areaMapMsg.address,
              'lng':this.$refs.areaMapMsg.lng,
              'lat':this.$refs.areaMapMsg.lat,
              'laboratory': this.dataForm.laboratory,
              'leadMan': this.dataForm.leadMan,
              'remarks': this.dataForm.remarks
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    /*this.visible = false;*/
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                    this.$refs.SidesLip.sideClose();
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      },
      //图片上传
      handleAvatarSuccess(res, file, type) {
        if (res && res.code === 0) {
          this.dataForm.factoryLogo = res.data.fileUrl;
        } else {
          this.$message.error(res.msg);
        }
      },
      beforeAvatarUpload(file) {
        const isJPG =
                file.type === "image/jpeg" ||
                file.type === "image/bmp" ||
                file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 8;
        if (!isJPG) {
          this.$message.error("上传图片格式不支持!");
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 8MB!");
        }
        return isJPG && isLt2M;
      },
      //获取品牌下拉
      getBrandList(){
        brandList({id:this.dataForm.id}).then(res => {
          let { list } = res.data
          this.dataForm.list = list
        })
      },
      sideClose() {
        this.$refs.SidesLip.sideClose();
      },
      //---------------------------------地区插件&地图插件---------------------------
    }
  }
</script>
<style lang="scss">
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .class_map_lib{
      font-weight:800;
  }
.sides-space {
   h2{
     margin: 20px 0;
     font-weight:800
    }
 }

</style>
