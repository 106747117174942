<template>
  <div class="app-product-container">
    <router-link to="/searchhistoryList">
      <div
        style="float: right;color: rgb(239 63 8)right: 30px;position: fixed;top: 115px;right:30px"
        v-if="this.noOperation > 0"
      >
        您还有{{ noOperation }}条搜索历史未处理，点击去处理
      </div>
    </router-link>
    <div class="logo">
      <img
        :src="localizeAsset('pro_bg.png')"
        class="pro_bg"
        style="width: 600px; height: auto"
      />
      <!-- <svg-icon class="pro_bg" style="width: 600px;height: 300px;" icon-class="pro_bg" /> -->
    </div>
    <div class="main">
      <div class="c_top" ref="c_top">
        <div class="c_t_radiocontent">
          <el-radio-group v-model="inputType" @change="inputTypeChange">
            <el-radio-button label="0">
              <template v-if="inputType === '0'"
                ><img src="../../assets/1_active.png"
              /></template>
              <template v-else><img src="../../assets/1.png" /></template>
              <span>{{ $t('scan_with_device') }}</span>
            </el-radio-button>
            <el-radio-button label="1">
              <template v-if="inputType === '1'"
                ><img src="../../assets/2_active.png"
              /></template>
              <template v-else><img src="../../assets/2.png" /></template>
              <span>{{ $t('search_by_color_chart') }}</span>
            </el-radio-button>
            <el-radio-button label="2">
              <template v-if="inputType === '2'"
                ><img src="../../assets/3_active.png"
              /></template>
              <template v-else><img src="../../assets/3.png" /></template>
              <span>{{ $t('search_by_color_picker') }}</span>
            </el-radio-button>
            <el-radio-button label="3">
              <template v-if="inputType === '3'"
                ><img src="../../assets/4_active.png"
              /></template>
              <template v-else><img src="../../assets/4.png" /></template>
              <span>{{ $t('search_by_number') }}</span>
            </el-radio-button>
          </el-radio-group>
        </div>
        <div class="c_t_inputcontent">
          <el-select
            v-model="deviceType"
            :placeholder="$t('select_device_brand')"
            v-show="inputType == '0'"
          >
            <el-option
              v-for="item in sessiondataList"
              :key="item.id"
              :label="selectName(item.brandName, item.deviceNo)"
              :value="item.id + item.readMode"
            ></el-option>
          </el-select>
          <el-select
            v-model="colorNoType"
            :placeholder="$t('select_color_chart_brand')"
            v-show="inputType == '1'"
            @change="handleChangeColorCategory"
          >
            <el-option
              v-for="item in colorBookCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :render="renderItem"
            ></el-option>
          </el-select>
          <el-select
            v-model="searchNoType"
            :placeholder="$t('select_search_type')"
            @change="searchNoTypeChange"
            v-show="inputType == '3'"
          >
            <el-option :label="$t('product_number')" value="1"></el-option>
            <el-option :label="$t('batch_number')" value="2"></el-option>
          </el-select>
          <div class="c_t_i_left">
            <div class="c_t_i_l_top">
              <el-autocomplete
                v-if="inputType == '1'"
                v-model="ralColorNo"
                :fetch-suggestions="querySearch"
                :placeholder="$t('input_color_code')"
                :trigger-on-focus="true"
                @select="handleSelect"
                size="small"
                :debounce="10"
                ref="mycolorNoType"
                style="width: 300px"
              >
                <template slot-scope="{ item }">
                  <div>
                    <span
                      class="color-card-item"
                      :style="{ backgroundColor: item.hex }"
                    ></span>
                    {{ item.value }}
                  </div>
                </template>
              </el-autocomplete>
              <el-input
                v-if="inputType == '3'"
                style="width: 300px"
                v-model="productNo"
                :placeholder="$t('please_enter_product_or_batch')"
                maxlength="100"
              ></el-input>
              <el-input
                placeholder="L*"
                v-model="Lvaule"
                v-if="inputType == '0' || inputType == '2'"
                oninput="value=value.replace(/[^\d.]/g,'');if(value.startsWith('-00')){value = '-0'};if(value.startsWith('00')){value = 0} if(value.startsWith('-')){var a=value.split('-'); if(a.length>=2){value = '-' + a[1]}} else {var a=value.split('-');
              if(a.length>=2){value = a[0]}}; var a=value.split('.'); if(a[0]=='-'){value = '-'} else if(!a[0]){value = ''} else if(a.length>=2){value=a[0]+'.'+a[1]} else {value=a[0]}"
                @blur="inputBlur"
              >
                <template slot="prepend"
                  ><div
                    style="padding: 10px"
                    :style="{
                      background: hexStr,
                      borderColor: hexStr,
                      color: textStr,
                      width: '100%',
                      height: '100%'
                    }"
                  >
                    <b>L*a*b*</b>
                  </div></template
                >
              </el-input>
              <el-input
                placeholder="a*"
                style="border-left: 1px solid #cccccc; width: 150px"
                v-model="Avaule"
                v-if="inputType == '0' || inputType == '2'"
                oninput="value=value.replace(/[^\-\d.]/g,'');if(value.startsWith('-00')){value = '-0'};if(value.startsWith('00')){value = 0} if(value.startsWith('-')){var a=value.split('-'); if(a.length>=2){value = '-' + a[1]}} else {var a=value.split('-');
              if(a.length>=2){value = a[0]}}; var a=value.split('.'); if(a[0]=='-'){value = '-'} else if(!a[0]){value = ''} else if(a.length>=2){value=a[0]+'.'+a[1]} else {value=a[0]}"
                @blur="inputBlur"
              >
              </el-input>
              <el-input
                placeholder="b*"
                style="border-left: 1px solid #cccccc; width: 150px"
                v-model="Bvaule"
                v-if="inputType == '0' || inputType == '2'"
                oninput="value=value.replace(/[^\-\d.]/g,'');if(value.startsWith('-00')){value = '-0'};if(value.startsWith('00')){value = 0} if(value.startsWith('-')){var a=value.split('-'); if(a.length>=2){value = '-' + a[1]}} else {var a=value.split('-');
              if(a.length>=2){value = a[0]}}; var a=value.split('.'); if(a[0]=='-'){value = '-'} else if(!a[0]){value = ''} else if(a.length>=2){value=a[0]+'.'+a[1]} else {value=a[0]}"
                @blur="inputBlur"
              >
              </el-input>
              <el-color-picker
                class="color"
                style="margin-top: 3px"
                @active-change="getcolor"
                v-model="color1"
                v-if="inputType == '2'"
              ></el-color-picker>
            </div>
          </div>
          <el-button
            class="btn"
            type="primary"
            @click="getSetmessage"
            :disabled="isdisabled"
            :loading="loading"
            v-if="inputType === '0' && deviceType"
            ><span>{{ $t('read_data_device') }}</span
            ><img src="../../assets/usb.png" height="28" width="28"
          /></el-button>

          <el-button
            class="btn"
            type="primary"
            @click="syncWirelessDeviceData"
            v-if="inputType === '0' && this.getCurrentDeviceSupportsWifi()"
            ><span>{{ $t('read_data_wireless') }}</span
            ><img
              src="../../assets/wireless_connection.png"
              height="32"
              width="32"
          /></el-button>

          <el-checkbox
            v-model="fuzzyMatching"
            :value="true"
            v-if="inputType == '3' && searchNoType == '1'"
            style="height: 40px; line-height: 40px; margin-left: 20px"
            >{{ $t('fuzzy_match') }}</el-checkbox
          >
        </div>
        <inline-message ref="wifiReadMessage" style="margin:15px" />
      </div>
      <div class="c_main" style="border: none">
        <el-form
          :inline="true"
          size="small"
          :model="dataForm"
          ref="dataForm"
          @keyup.enter.native="restPage()"
        >
          <!--品牌选择标签-->
          <!-- <el-form-item label="产品品牌">
            <el-select v-model="dataForm.brandId" size="small" placeholder="请选择品牌" @change="brandOnchange()" :disabled="factoryBrandState">
              <el-option label="全部" value="-1"></el-option>
              <el-option v-for="item in  brandList" :key="item.id" :label="item.brandName" :value="item.id" size="small"></el-option>
            </el-select>
          </el-form-item> -->
          <div class="ces-main">
            <search-form
              ref="form"
              size="mini"
              labelWidth="120px"
              :searchData="searchData"
              :searchForm="searchForm"
              :notIncluded="[]"
            ></search-form>
          </div>
          <!--工厂选择标签/跟产品品牌做级联-->
          <el-form-item :label="$t('product_factory')">
            <el-select
              v-model="dataForm.factoryId"
              size="small"
              :placeholder="$t('select_factory')"
            >
              <el-option :label="$t('all')" value="-1"></el-option>
              <el-option
                v-for="item in factoryList"
                :key="item.id"
                :label="item.factoryName"
                :value="item.id"
                size="small"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('product_status')">
            <el-select
              v-model="dataForm.state"
              size="small"
              :placeholder="$t('select_status')"
            >
              <el-option :label="$t('all')" value="-1"></el-option>
              <el-option
                v-for="item in testList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                size="small"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('is_base')">
            <el-select
              v-model="dataForm.isBase"
              size="small"
              placeholder="请选择"
            >
              <el-option :label="$t('all')" value="-1"></el-option>
              <el-option :label="$t('no')" value="0"></el-option>
              <el-option :label="$t('yes')" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('inventory')">
            <el-select
              v-model="dataForm.hasProductNum"
              size="small"
              placeholder="请选择"
            >
              <el-option :label="$t('all')" value="-1"></el-option>
              <el-option :label="$t('in_stock')" value="0"></el-option>
              <el-option :label="$t('out_of_stock')" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('customer_name')">
            <el-input
              :placeholder="$t('customer_name')"
              style="width: 190px"
              v-model="customerName"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('notes')">
            <el-input
              :placeholder="$t('notes')"
              style="width: 190px"
              v-model="remarks"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="width: 100%; text-align: center; margin-top: 30px">
          <el-button
            class="btn"
            @click="reset('dataForm')"
            style="width: 100px"
            >{{ $t('clear_all') }}</el-button
          >
          <el-button
            class="btn"
            type="primary"
            style="width: 350px"
            icon="el-icon-search"
            @click="isProductCategory"
            >{{ $t('search') }}</el-button
          >
          <el-button
            v-if="
              dataForm.brandId == 'dcf7bb23ebeb4509ac6905c6d29b24e6' &&
              isAuth('mixpowder:mixpowder:search')
            "
            class="btn"
            type="blue"
            style="width: 350px"
            icon="el-icon-search"
            @click="mixpowder"
            >混粉推荐</el-button
          >
        </div>
      </div>
    </div>
    <div class="divTab">
      <div
        style="
          font-size: 16px;
          color: #000000;
          padding: 20px 15px 15px 35px;
          font-weight: 500;
          border-bottom: 1px solid #f0f0f0;
          margin-bottom: 15px;
        "
      >
        {{ $t('search_results') }}
      </div>
      <el-tabs v-model="activeNameE00Type" type="card" @tab-click="getDataList">
        <el-tab-pane
          :label="
            isIntegral == '1'
              ? 'D65/10° SPIN'
              : $t('sort_multi_angle_color_diff')
          "
          name="first"
          v-if="inputType != '3'"
        ></el-tab-pane>
        <el-tab-pane
          :label="$t('sort_45deg_color_diff')"
          name="second"
          v-if="inputType != '3' && isIntegral != '1'"
        ></el-tab-pane>
      </el-tabs>
    </div>

    <div class="content">
      <el-row style="margin-bottom: 10px; padding-left: 20px">
        <div
          class="c_t_colorcontent"
          :style="{ background: hexStr, color: textStr }"
          v-if="
            inputType != '3' && historyId && dataList && dataList.length > 0
          "
        >
          <p>
            <b>L*a*b {{ labStr }}</b>
          </p>
          <p>{{ $t('standard_color') }}</p>
        </div>
        <div
          class="c_t_color_history"
          v-if="
            inputType != '3' && historyId && dataList && dataList.length > 0
          "
        >
          <p class="evaluate-results">{{ $t('evaluate_results') }}</p>
          <p v-if="historyOpt === 0">
            <el-button type="success" @click="handleUpdateHistory(2)">{{
              $t('perfect_match')
            }}</el-button>
            <el-button
              type="info"
              style="margin-left: 30px"
              @click="handleUpdateHistory(4)"
              >{{ $t('fair_match') }}</el-button
            >
            <el-button
              type="warning"
              style="margin-left: 30px"
              @click="handleUpdateHistory(3)"
              >{{ $t('poor_match') }}</el-button
            >
          </p>
          <p v-if="historyOpt === 1" class="success">
            {{ $t('feedback_recorded') }}
          </p>
          <p v-if="historyOpt === 2" class="success">
            {{ $t('feedback_recorded') }}
          </p>
          <p v-if="historyOpt === 1 || historyOpt === 2">
            <el-button
              style="margin-right: 30px"
              v-if="historyOpt === 1"
              @click="handleUpdateHistoryDetail"
              >{{ $t('detailed_feedback') }}</el-button
            >
            <span>{{ $t('see_results') }}</span>
            <el-link
              style="margin-left: 5px"
              type="primary"
              @click="
                $router.push({
                  path: '/searchhistoryList'
                })
              "
              >{{ $t('search_history') }}</el-link
            >
          </p>
        </div>
      </el-row>
      <div class="c_m_cards" v-loading="dataListLoading">
        <!-- //@mouseover.prevent="mouseover(item)" @mouseout="isMaskShow=false"> -->
        <el-card
          :v-if="dataList.length > 0"
          :body-style="{ padding: '0px' }"
          v-for="(item, index) in dataList"
          :key="index"
          @mouseenter.native="enter(item.id)"
          @mouseleave.native="leave()"
        >
          <img
            @click="cpdetailsHandle(item.id, item.e00Value, item.e00lab2)"
            :src="item.picUrl"
            class="c_m_c_img"
            v-if="item.picUrl"
          />
          <div
            v-if="!item.picUrl && (item.hex || item.Hex)"
            :style="{ backgroundColor: item.hex || item.Hex }"
            class="c_m_c_img_"
          ></div>
          <div class="c_m_c_bot">
            <span class="c_m_c_b_tit"
              >{{ item.brandName == null ? 'TIGER' : item.brandName }} -
              {{ item.factoryName }}</span
            >
            <div class="c_m_c_b_txt">
              <p class="spanSize">
                <span class="spanSize">{{ $t('product_number') }}</span>
                {{ item.productNo == null ? $t('none') : item.productNo }}
              </p>
              <p class="spanSize">
                <span class="spanSize">{{ $t('batch_number') }}</span>
                {{ item.productOrder == '' ? $t('none') : item.productOrder }}
              </p>
              <p
                class="spanSize"
                v-if="
                  inputType != '3' ||
                  (fuzzyMatching == true && searchNoType == '1')
                "
              >
                <span class="spanSize">{{ $t('45deg_color_diff') }}</span>
                {{ item.e00lab2 == '-1' ? '0' : item.e00lab2 }}
              </p>
              <p
                class="spanSize"
                v-if="
                  inputType != '3' ||
                  (fuzzyMatching == true && searchNoType == '1')
                "
              >
                <span class="spanSize">{{ $t('multi_angle_color_diff') }}</span>
                {{ item.e00Value == '-1' ? '0' : item.e00Value }}
              </p>
              <p class="spanSize">
                <span class="spanSize">{{ $t('inventory') }}</span>
                {{ item.productNum == '-1' ? '0' : item.productNum }} kg
              </p>
              <!--L表示亮暗，+表示偏亮，-表示偏暗-->
              <!--A表示红绿，+表示偏红，-表示偏绿-->
              <!--B表示黄蓝，+表示偏黄，-表示偏蓝-->
              <p
                class="spanSize"
                v-if="
                  inputType != '3' ||
                  (fuzzyMatching == true && searchNoType == '1')
                "
              >
                <span class="spanSize">ΔL</span>
                {{
                  item.del == '0'
                    ? $t('no_bias')
                    : item.del > '0'
                    ? $t('white_bias')
                    : $t('black_bias')
                }}
                ({{ item.del }})
              </p>
              <p
                class="spanSize"
                v-if="
                  inputType != '3' ||
                  (fuzzyMatching == true && searchNoType == '1')
                "
              >
                <span class="spanSize">Δa</span>
                {{
                  item.dea == '0'
                    ? $t('no_bias')
                    : item.dea > '0'
                    ? $t('red_bias')
                    : $t('green_bias')
                }}
                ({{ item.dea }})
              </p>
              <p
                class="spanSize"
                v-if="
                  inputType != '3' ||
                  (fuzzyMatching == true && searchNoType == '1')
                "
              >
                <span class="spanSize">Δb</span>
                {{
                  item.deb == '0'
                    ? $t('no_bias')
                    : item.deb > '0'
                    ? $t('yellow_bias')
                    : $t('blue_bias')
                }}
                ({{ item.deb }})
              </p>
            </div>
          </div>
          <div class="layout-mask" v-show="activeId === item.id">
            <div class="main-mask">
              <el-button
                type="primary"
                size="small"
                @click="
                  cpdetailsHandle(
                    item.id,
                    item.e00Value,
                    item.e00lab2,
                    item.brandId
                  )
                "
                style=""
                >{{ $t('details') }}</el-button
              >
              <!-- <el-button
                v-if="isAuth('recipe:recipe:info')"
                type="primary"
                size="small"
                @click="addRecipeHandle(item.id)"
                icon="el-icon-search"
                >查看配方</el-button
              > -->
            </div>
          </div>
        </el-card>
        <p
          v-if="dataList.length == 0 && nodata"
          style="
            margin: 10px auto;
            font-size: 14px;
            color: #999;
            display: flex;
            align-items: center;
          "
        >
          {{ $t('no_data_current_search') }}
        </p>
        <!-- </div> -->
      </div>
      <div id="fenye">
        <el-pagination
          size="small"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[18, 36, 54, 108]"
          :page-size="pageSize"
          :total="totalPage"
          layout="prev, pager, next"
        ></el-pagination>
      </div>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
    <add-recipe
      v-if="addRecipeVisible"
      ref="addRecipe"
      @refreshDataList="getDataList"
    ></add-recipe>
    <update-history
      v-if="updateHistoryVisible"
      ref="updateHistory"
      @refreshDataList="historyOpt = 2"
    ></update-history>
    <cpdetails v-if="cpdetailsVisible" ref="cpdetails"></cpdetails>
    <sample v-if="sampleVisible" ref="sample"></sample>
    <device-boot v-if="deviceBootShow" ref="deviceBoot" />
    <Footer />
  </div>
</template>

<script>
import resize from './resize'
import Cookies from 'js-cookie'
import i18n from '@/i18n'
import moment from 'moment'
import 'moment/locale/zh-cn'
import {
  getBrand,
  syncDevice,
  getWirelessDeviceData,
  serachList,
  selectDevice,
  getLAB,
  gethex
} from '@/api/modules/product'
import SearchForm from '@/components/form.vue'
import { getAttrAndValueListInfo } from '@/api/modules/productattr'
import { factoryList } from '@/api/modules/factory'
import { findCount } from '@/api/modules/searchhistory'
import {
  getColorBookList,
  colorBookCategoryListApi
} from '@/api/modules/colorbook'
import { setDeviceState } from '@/api/modules/device'
import AddOrUpdate from './add-or-update'
import UpdateHistory from '@/views/searchhistoryList/add-or-update'
import cpdetails from './cpdetails'
import AddRecipe from './recipe'
import sample from './sample'
import { update as updateHistoryApi } from '@/api/modules/searchhistoryList'

import {
  getProductAttrValue,
  getTypeSurface
} from '@/api/modules/productattrvalue'
import { selectDeviceList } from '@/api'
import { getSecondsAgoMessage } from '../../util/i18n/time'
import InlineMessage from '../../components/inlineMessage.vue'
export default {
  mixins: [resize],
  data() {
    return {
      cookie: {},
      sessiondataList: [],
      searchForm: [
        //这里是渲染查询表单的表头和类型的数据
        // {type:'Input',label:'姓名',prop:'name', width:'180px',placeholder:'请输入姓名...'},
        // {type:'Select',label:'性别',prop:'sex',width:'180px',options:sexs,props:sexProps,change:row=>'',placeholder:'请选择性别...'},
      ],
      searchData: {
        //查询表单的对应的值
        // name:'',
        // sex:null,
      },
      websock: null,
      hsvStr: { h: 0, s: 0, v: 0 },
      isMaskShow: false,
      activeId: null,
      activeNameE00Type: 'first',
      e00Type: '1', //色差类型（综合色差值和45度色差值）
      remarks: '',
      customerName: '',
      noOperation: '',
      loading: false,
      device: {
        id: ''
      },
      deviceList: [],
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 0, // 设置定时器时间
      is_query: false,
      restaurants: '',
      dataForm: {
        searchType: '1',
        e00lab2: '',
        orderId: '',
        e00Value: '',
        productName: '',
        id: '',
        productNo: '',
        picUrl: '',
        qrUrl: '',
        ralColorNo: '',
        gloss: '',
        glossValue: '',
        surface: '',
        colorSeries: '',
        colorSeriesName: '',
        productPerformance: '',
        otherPerformance: '',
        productCategory: '',
        productSeries: '',
        productHierarchy: '',
        industryId: '',
        industryName: '',
        factoryId: '',
        brandId: '',
        description: '',
        labAve: '',
        l1: '',
        a1: '',
        b1: '',
        l2: '',
        a2: '',
        b2: '',
        l3: '',
        a3: '',
        b3: '',
        lAve: '',
        aAve: '',
        bAve: '',
        isOpen: '',
        state: '',
        isBase: '-1',
        hasProductNum: '-1',
        makeFactory: ''
      },
      factoryList: [],
      dataList: [],
      performanceList: [],
      productSurfaceList: [],
      productGlossList: [],
      otherPerformanceList: [],
      ProductCategoryList: [],
      productSeriesList: [],
      FactoryBrand: [],
      factoryBrandState: false,
      glossState: false,
      productNoState: false,
      sampleVisible: false,
      deviceBootShow: false,
      pageIndex: 1,
      pageSize: 18,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      addRecipeVisible: false,
      a: '',
      s: '',
      productNo: '',
      Lvaule: '',
      Avaule: '',
      Bvaule: '',
      sampleType: '',
      sampleUserId: '',
      sampleOrderNo: '',
      sampleOrderId: '',
      ralColorNo: '',
      type: '2',
      color1: '#1A2F44',
      labStr: '',
      hexStr: '',
      textStr: '',
      cpdetailsVisible: false,
      isdisabled: false,
      nodata: false,
      inputType: '0',
      // 搜索历史id
      historyId: undefined,
      // 搜索历史操作 0.未评价 1.评价状态 2.详细评价
      historyOpt: 0,
      updateHistoryVisible: false,
      deviceType: '',
      colorNoType: '',
      searchNoType: '',
      testList: [
        {
          value: '0',
          label: this.$t('sampling_process')
        },
        {
          value: '1',
          label: this.$t('sample_product')
        },
        {
          value: '2',
          label: this.$t('production_product')
        }
      ],

      fuzzyMatching: false, //精准查色，是否模糊匹配
      isIntegral: '', //是否积分球
      colorBookCategoryList: []
    }
  },
  components: {
    SearchForm,
    AddOrUpdate,
    UpdateHistory,
    cpdetails,
    sample,
    AddRecipe,
    Footer: () => import('../homePage/admin/components/Footer'),
    DeviceBoot: () => import('@/components/DeviceBoot'),
    InlineMessage
  },
  computed: {
    devicedata() {
      return this.$store.state.user.devicedata
    }
  },
  watch: {
    devicedata(val) {
      this.deviceType = val.id + val.readMode
      this.selectDeviceList()
    }
  },
  created() {
    let id = this.$store.getters.userDevice.id
    this.device.id = id
    //this.getProductAttrValue();
    this.getColorBookCategoryList()
    this.getAttrAndValueListInfo()
    this.getFactoryList()
    this.s = ''
    this.remarks = ''
    this.customerName = ''
    this.Lvaule = ''
    this.Avaule = ''
    this.Bvaule = ''
    this.color1 = ''
    this.ralColorNo = ''
    this.productNo = ''
    this.inputType = '0'
    this.deviceType = ''
    this.dataForm.factoryId = '-1'
    this.dataForm.productCategory = '-1'
    this.dataForm.surface = '-1'
    this.dataForm.productSeries = '-1'
    this.dataForm.state = '-1'
    this.dataForm.gloss = '-1'
    this.dataForm.glossValue = ''
    this.dataForm.productNo = ''
    this.dataForm.e00Value = ''
    this.dataForm.isBase = '-1'
    this.dataForm.hasProductNum = '-1'
    this.labStr = ''
    this.hexStr = ''
    this.textStr = ''
    this.dataList = []
    this.totalPage = 0
    this.nodata = true
    this.initColor()
    this.selectDeviceList()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
    this.initDom()
  },
  methods: {
    // 匹配历史详情
    handleUpdateHistoryDetail() {
      this.updateHistoryVisible = true
      this.$nextTick(() => {
        this.$refs.updateHistory.init(this.historyId)
      })
    },
    // 处理匹配历史
    handleUpdateHistory(operation) {
      let data = { id: this.historyId, operation: operation }
      updateHistoryApi(data).then(({ data }) => {
        if (data && data.code === 0) {
          this.historyOpt = 1
          this.$message({
            message: this.$t('success'),
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false
            }
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    handleChangeColorCategory() {
      this.ralColorNo = ''
    },
    getColorBookCategoryList() {
      colorBookCategoryListApi().then(({ data }) => {
        this.colorBookCategoryList = []
        if (data && data.code === 'C0' && data.list && data.list.length > 0) {
          this.colorBookCategoryList = data.list
        }
      })
    },
    inputBlur() {
      var Lvaule = this.Lvaule
      var Avaule = this.Avaule
      var Bvaule = this.Bvaule
      if (Lvaule) {
        var l = Lvaule.split('.')
        if (!l[0]) {
          this.Lvaule = ''
        } else if (l.length >= 2 && l[1]) {
          this.Lvaule = l[0] + '.' + l[1]
        } else {
          this.Lvaule = l[0]
        }
      } else {
        this.Lvaule = ''
      }
      if (Avaule) {
        var a = Avaule.split('.')
        if (!a[0]) {
          this.Avaule = ''
        } else if (a.length >= 2 && a[1]) {
          this.Avaule = a[0] + '.' + a[1]
        } else {
          this.Avaule = a[0]
        }
      } else {
        this.Avaule = ''
      }
      if (Bvaule) {
        var b = Bvaule.split('.')
        if (!b[0]) {
          this.Bvaule = ''
        } else if (b.length >= 2 && b[1]) {
          this.Bvaule = b[0] + '.' + b[1]
        } else {
          this.Bvaule = b[0]
        }
      } else {
        this.Bvaule = ''
      }
    },
    selectName(brandName, deviceNo) {
      if (brandName && deviceNo) {
        return brandName + '-' + deviceNo
      } else if (brandName && !deviceNo) {
        return brandName
      } else if (!brandName && deviceNo) {
        return deviceNo
      }
    },
    //设备查色，获取手动设备
    async selectDeviceList() {
      const params = {
        readMode: ''
      }
      const { data } = await selectDeviceList(params)
      if (data && data.code === 0) {
        this.sessiondataList = data.devices
        if (
          Cookies.getJSON('devicedata') &&
          Cookies.getJSON('devicedata') != 'undefined'
        ) {
          this.sessiondataList.map((item, index) => {
            if (item.id === Cookies.getJSON('devicedata').id) {
              this.sessiondataList.splice(index, 1)
            }
          })
          this.sessiondataList.unshift(Cookies.getJSON('devicedata'))
          this.cookie = Cookies.getJSON('devicedata')
        } else {
          // this.deviceType = ''
          this.cookie = ''
        }
        if (this.deviceType == '' && this.sessiondataList.length > 0) {
          this.deviceType =
            this.sessiondataList[0].id + this.sessiondataList[0].readMode
        }
      } else {
        this.sessiondataList = []
        this.deviceType = ''
        this.cookie = ''
      }
    },
    initDom() {
      if (this.$refs.ccccc) {
        let $div = this.$refs.c_top
        let width =
          $div.style.width ||
          $div.clientWidth ||
          $div.offsetWidth ||
          $div.scrollWid
        this.$refs.ccccc.style.width = width / 4 + 'px'
        if (width / 4 < 300) {
          this.$refs.ccccc.style.width = '100px'
          this.$refs.ccccc.style.height = '47px'
        }
      }
    },
    enter(id) {
      this.activeId = id
    },
    leave() {
      this.activeId = null
    },
    findCount() {
      let params = {}
      findCount(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.noOperation = data.count
        }
      })
    },
    //产品分类联动产品表面
    getTypeSurface(e) {
      let params = {
        id: e
      }
      getTypeSurface(params).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.list.length > 0) {
            this.productSurfaceList = data.list
          } else {
            getProductAttrValue().then(({ data }) => {
              if (data && data.code === 0) {
                for (var i = 0; i < data.list.length; i++) {
                  if (data.list[i].productAttrType === '1') {
                    //表面
                    this.productSurfaceList = data.list[i].productAttrValue
                  }
                }
              }
            })
          }
        }
      })
    },
    getAttrAndValueListInfo: function () {
      this.searchData = {}
      this.searchForm = []
      this.tableCols = []
      getAttrAndValueListInfo({ isUniversal: '0' }).then(({ data }) => {
        if (data && data.code === 0) {
          data.data.forEach((e) => {
            var obj = { type: '', label: '', labelEn: '', width: '100%' }
            if (e.type == '1') {
              // 如果是单选
              obj.type = 'Select'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.label = e.value
              obj.labelEn = e.valueEn
            }
            if (e.type == '2') {
              obj.type = 'SelectMultiple'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
              obj.valueEn = e.valueEn
            }
            if (e.type == '3') {
              obj.type = 'Cascader'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
              obj.valueEn = e.valueEn
            }
            obj.label = e.label
            obj.labelEn = e.labelEn
            this.$set(this.searchData, e.productAttrId, e.value)
            this.searchForm.push(obj)
          })
        } else {
          this.$message.error(data.msg)
          this.loading2 = false
        }
      })
    },
    renderItem(createElement) {
      return createElement('div', 'test')
    },
    getProductAttrValue() {
      let params = {}
      getProductAttrValue(params).then(({ data }) => {
        if (data && data.code === 0) {
          for (var i = 0; i < data.list.length; i++) {
            if (data.list[i].productAttrType === '1') {
              //表面
              this.productSurfaceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '3') {
              //系列
              this.productSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '6') {
              //主题
              this.productHierarchyList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '2') {
              //色系
              this.colorSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '7') {
              //性能
              this.performanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '4') {
              //应用
              this.industryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '8') {
              //功能
              this.otherPerformanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '5') {
              //分类
              this.ProductCategoryList = data.list[i].productAttrValue
              /* if(this.ProductCategoryList !=null && this.ProductCategoryList.length>0){
                   this.dataForm.productCategory=this.ProductCategoryList[0].id;
               }*/
            } else if (data.list[i].productAttrType === '9') {
              //光泽
              this.productGlossList = data.list[i].productAttrValue
            }
          }
        }
      })
    },
    //查色跳转
    initColor() {
      //产品工厂；产品表面；产品分类；产品光泽；lab平均值
      if (this.$route.query.factoryId) {
        this.dataForm.factoryId = this.$route.query.factoryId
      }
      if (this.$route.query.surface) {
        this.dataForm.surface = this.$route.query.surface
      }
      if (this.$route.query.productCartegry) {
        this.dataForm.productCategory = this.$route.query.productCartegry
      }
      if (this.$route.query.gloss) {
        this.dataForm.gloss = this.$route.query.gloss
      }
      if (this.$route.query.sampleType) {
        this.sampleType = this.$route.query.sampleType
      }
      if (this.$route.query.userId) {
        this.sampleUserId = this.$route.query.userId
      }
      if (this.$route.query.sampleOrderNo) {
        this.sampleOrderNo = this.$route.query.sampleOrderNo
      }
      if (this.$route.query.id) {
        this.sampleOrderId = this.$route.query.id
      }

      if (
        this.$route.query.lAve &&
        this.$route.query.aAve &&
        this.$route.query.bAve
      ) {
        this.inputType = '0'
        this.Lvaule = this.$route.query.lAve
        this.Avaule = this.$route.query.aAve
        this.Bvaule = this.$route.query.bAve
      }
      this.labStr = ''
      this.hexStr = ''
      this.textStr = ''
    },
    //获取颜色
    getcolor(val) {
      this.s = ''
      this.ralColorNo = ''
      this.productNo = ''
      this.inputType = '2'
      this.dataList = []
      this.totalPage = 0
      this.color1 = this.colorchange(val)
      this.dataList = []
      this.totalPage = 0
      this.resetOther()
      this.getlabvalue()
    },
    getlabvalue() {
      this.ralColorNo = ''
      this.productNo = ''
      var hex = this.color1
      var params = {
        hex: hex
      }
      getLAB(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.Lvaule = data.lab[0]
          this.Avaule = data.lab[1]
          this.Bvaule = data.lab[2]
          this.a = data.lab.join(',')
          //this.getDataList();
        }
      })
      this.labStr = ''
      this.hexStr = ''
      this.textStr = ''
    },
    gethexvalue() {
      var params = {
        lab: this.a
      }
      gethex(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.color1 = data.hex
        }
      })
    },
    //rgb颜色转换hsv
    rgb2hsv() {
      var rr,
        gg,
        bb,
        r = arguments[0] / 255,
        g = arguments[1] / 255,
        b = arguments[2] / 255,
        h,
        s,
        v = Math.max(r, g, b),
        diff = v - Math.min(r, g, b),
        diffc = function (c) {
          return (v - c) / 6 / diff + 1 / 2
        }

      if (diff == 0) {
        h = s = 0
      } else {
        s = diff / v
        rr = diffc(r)
        gg = diffc(g)
        bb = diffc(b)

        if (r === v) {
          h = bb - gg
        } else if (g === v) {
          h = 1 / 3 + rr - bb
        } else if (b === v) {
          h = 2 / 3 + gg - rr
        }
        if (h < 0) {
          h += 1
        } else if (h > 1) {
          h -= 1
        }
      }
      return {
        h: h * 360,
        s: s,
        v: v
      }
    },
    //hex转rgb
    hexToRgba(hex) {
      return [
        parseInt('0x' + hex.slice(1, 3)),
        parseInt('0x' + hex.slice(3, 5)),
        parseInt('0x' + hex.slice(5, 7))
      ]
    },
    //rgb颜色转换hex
    colorchange(color) {
      //debugger
      var regexp = /[0-9]{0,3}/g
      var re = color.match(regexp) //利用正则表达式去掉多余的部分，将rgb中的数字提取
      var hexColor = '#'
      var hex = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F'
      ]
      for (var i = 0; i < re.length; i++) {
        var r = null,
          c = re[i],
          l = c
        var hexAr = []
        while (c > 16) {
          r = c % 16
          c = (c / 16) >> 0
          hexAr.push(hex[r])
        }
        hexAr.push(hex[c])
        if (l < 16 && l != '') {
          hexAr.push(0)
        }
        hexColor += hexAr.reverse().join('')
      }
      return hexColor
    },
    //设备引导
    handleDeviceBoot() {
      this.deviceBootShow = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.deviceBoot.init()
        })
      })
    },
    getCurrentDeviceSupportsWifi() {
      var deviceId = this.deviceType.substring(0, this.deviceType.length - 1)
      if (this.sessiondataList && this.sessiondataList.length > 0) {
        var currentDevice = this.sessiondataList.filter(
          (item) => item.id == deviceId
        )[0]
        if (currentDevice != undefined && currentDevice.isWifi == '0') {
          return true
        }
      }
      return false
    },
    // 获取线上数据
    syncWirelessDeviceData() {
      var deviceId = this.deviceType.substring(0, this.deviceType.length - 1)
      getWirelessDeviceData({ deviceId: deviceId }).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.LABvalue) {
            ;(this.l1 = data.LABvalue.l1),
              (this.a1 = data.LABvalue.a1),
              (this.b1 = data.LABvalue.b1),
              (this.l2 = data.LABvalue.l2),
              (this.a2 = data.LABvalue.a2),
              (this.b2 = data.LABvalue.b2),
              (this.l3 = data.LABvalue.l3),
              (this.a3 = data.LABvalue.a3),
              (this.b3 = data.LABvalue.b3),
              (this.Lvaule = data.LABvalue.lAve),
              (this.Avaule = data.LABvalue.aAve),
              (this.Bvaule = data.LABvalue.bAve),
              (this.hexStr = data.hex),
              (this.labStr =
                this.Lvaule + ' ' + this.Avaule + ' ' + this.Bvaule + ' '),
              this.setHexTextStr(this.hexStr)

            this.$refs.wifiReadMessage.show(
              this.$t('read_success', {
                x: getSecondsAgoMessage(data.secondsAgo)
              })
            )

            // 重置查询结果
            this.dataList = []
            this.totalPage = 0
          } else {
            this.$message.error(this.$t('no_data'))
          }
        } else {
          this.$message.error(this.$t('no_data'))
        }
      })
    },
    //获取设备
    getSetmessage() {
      this.dataForm.searchType = '1'
      var id = this.$store.getters.userDevice.id
      if (null == id || '' == id) {
        this.handleDeviceBoot()
        return
      }
      syncDevice({ deviceId: this.$store.getters.userDevice.id }).then(
        ({ data }) => {
          this.loading = true
          this.isdisabled = true
          //  if (data && data.code === 0) {
          //    this.initWebSocket();
          if (data && data.code === 0) {
            this.initWebSocket()
            this.timer = setInterval(() => {
              //如果已经同步就关闭定时任务
              this.$message.warning(this.$t('device_sync_in_progress'))
              this.timerNum = this.timerNum + 1
              if (this.timerNum == 5) {
                this.clearTimer() // 关闭定时器
                //恢复设备状态
                this.setDeviceState()
                this.loading = false
                this.isdisabled = false
              }
            }, 5000)
          } else {
            this.$message.error(data.msg)
            this.loading = false
            this.isdisabled = false
          }
          //  } else {
          //    this.$message.error(data.msg);
          //    this.loading = false;
          //    this.isdisabled = false;
          //  }
        }
      )
    },
    clearTimer() {
      //清除定时器
      window.clearInterval(this.timer)
      this.timer = null
      this.timerNum = 0
    },
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    MLGB() {
      if (this.dataForm.gloss == '-1') {
        this.glossState = false
      } else {
        this.glossState = true
      }

      this.dataForm.glossValue = ''
    },

    brandOnchange() {
      this.dataForm.factoryId = ''
      this.getFactoryList()
    },
    reset(dataForm) {
      this.$nextTick(() => {
        this.$refs.dataForm.resetFields()
      })
      this.getProductAttrValue()
      this.getFactoryList()
      this.s = ''
      this.Lvaule = ''
      this.Avaule = ''
      this.Bvaule = ''
      this.color1 = '#1A2F44'
      this.labStr = ''
      this.hexStr = ''
      this.textStr = ''
      this.ralColorNo = ''
      this.productNo = ''
      this.dataForm.glossValue = ''
      this.dataList = []
      this.totalPage = 0
      this.dataForm.state = '-1'
      this.dataForm.factoryId = '-1'
      this.dataForm.productCategory = '-1'
      this.dataForm.surface = '-1'
      this.dataForm.gloss = '-1'
      this.dataForm.productNo = ''
      this.dataForm.productSeries = '-1'
      this.dataForm.isBase = '-1'
      this.dataForm.hasProductNum = '-1'
      this.is_query = false
      this.glossState = false
      this.productNoState = false
      this.remarks = ''
      this.customerName = ''
      if (this.searchData) {
        for (var i in this.searchData) {
          this.$set(this.searchData, i, {})
        }
        this.searchData = {}
      }
      this.resetOther()
      //this.findCount();
    },
    isProductCategory() {
      if (
        this.dataForm.productCategory == '' ||
        this.dataForm.productCategory == null
      ) {
        this.$message.error(this.$t('choose_product_category'))
      } else {
        this.getDataList()
      }
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.dataList = []
      if (this.activeNameE00Type === 'first') {
        this.e00Type = '1'
      } else if (this.activeNameE00Type === 'second') {
        this.e00Type = '2'
      }
      var productNo = this.productNo || this.dataForm.productNo

      var params = {
        searchData: this.searchData,
        //searchType:this.dataForm.searchType,
        state: this.dataForm.state,
        isBase: this.dataForm.isBase,
        hasProductNum: this.dataForm.hasProductNum,
        diviceId: this.$store.getters.userDevice.id,
        //brandId: this.dataForm.brandId,
        factoryId: this.dataForm.factoryId,
        page: this.pageIndex,
        limit: this.pageSize,
        otherPerformance: this.dataForm.otherPerformance,
        surface: this.dataForm.surface,
        gloss: this.dataForm.gloss,
        glossValue: this.dataForm.glossValue,
        lAve: this.Lvaule,
        aAve: this.Avaule,
        bAve: this.Bvaule,
        l1: this.dataForm.l1,
        a1: this.dataForm.a1,
        b1: this.dataForm.b1,
        l2: this.Lvaule,
        a2: this.Avaule,
        b2: this.Bvaule,
        l3: this.dataForm.l3,
        a3: this.dataForm.a3,
        b3: this.dataForm.b3,
        productCategory: this.dataForm.productCategory,
        productSeries: this.dataForm.productSeries,
        e00Value: this.dataForm.e00Value,
        productNo: productNo,
        ralColorNo: this.ralColorNo,
        type: this.type,
        // deviceType: this.deviceType,
        colorNoType: this.colorNoType,
        searchNoType: this.searchNoType,
        inputType: this.inputType,
        e00Type: this.e00Type,
        remarks: this.remarks,
        customerName: this.customerName,
        fuzzyMatching:
          this.searchNoType == '1' ? (this.fuzzyMatching ? '1' : '0') : '0',
        deviceId: this.deviceType.substring(0, this.deviceType.length - 1)
      }
      if (
        !(this.Lvaule && this.Avaule && this.Bvaule) &&
        !this.ralColorNo &&
        !productNo
      ) {
        this.dataList = []
        this.totalPage = 0
        this.$message.error(this.$t('enter_search_parameter'))
        this.color1 = '#1A2F44'
        this.dataListLoading = false
        return
      }
      // if (null != this.s) {
      //   this.gethexvalue();
      // }
      serachList(params).then(({ data }) => {
        this.dataForm.searchType = '1'
        this.historyId = data.historyId
        this.historyOpt = 0
        if (data && data.code === 0) {
          if (data.page.list.length > 0) {
            this.nodata = false
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.nodata = true
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          }
          this.isIntegral = data.isIntegral
          this.labStr = data.labStr
          this.hexStr = data.hexStr
          this.setHexTextStr(data.hexStr)
        } else {
          this.dataList = []
          this.totalPage = 0
          this.$message.error(data.msg)
          this.labStr = data.labStr
          this.hexStr = data.hexStr
        }
        this.dataListLoading = false
      })
      if (this.dataForm.lAve && this.dataForm.aAve && this.dataForm.bAve) {
        this.is_query = true
      }
    },
    setHexTextStr(hexStr) {
      this.hsvStr = hexStr
        ? this.rgb2hsv(
            this.hexToRgba(hexStr)[0],
            this.hexToRgba(hexStr)[1],
            this.hexToRgba(hexStr)[2]
          )
        : { h: 0, s: 0, v: 0 }
      // 浅色或者黄色，字体要黑色
      if (
        (this.hsvStr.s < 0.2 && this.hsvStr.v > 0.8) ||
        (this.hexToRgba(hexStr)[0] > 170 && this.hexToRgba(hexStr)[1] > 170)
      ) {
        this.textStr = '#333'
      } else {
        this.textStr = '#fff'
      }
    },
    //获取已授权设备
    getselectDevice() {
      let params = {}
      selectDevice(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.deviceList = data.deviceList
          //   this.device.deviceId = data.deviceList[0].deviceId;
        } else {
          this.deviceList = []
        }
      })
    },

    setDeviceState() {
      let params = {
        deviceId: this.$store.getters.userDevice.id
      }
      setDeviceState(params).then(({ data }) => {})
    },

    //获取品牌列表
    getBrandList() {
      let params = {}
      getBrand(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.brandList = data.list
        } else {
          this.brandList = []
        }
      })
    },
    querySearch(queryString, cb) {
      if (queryString) {
        setTimeout(() => {
          let params = {
            colorBookCategory: this.colorNoType,
            colorNo: this.ralColorNo
          }
          getColorBookList(params).then(({ data }) => {
            if (data && data.code === 0) {
              var restaurants = data.list
              restaurants.forEach((v) => {
                v.value = v.colorNo
              })
              cb(restaurants)
            }
          })
        }, 1000)
      }
    },

    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.colorNo
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    handleSelect(item) {},
    //获取会员数据
    getColorBookList() {
      let params = {
        type: this.colorNoType,
        colorNo: this.ralColorNo
      }
      this.restaurants = ''
      getColorBookList(params).then(({ data }) => {
        if (data && data.code === 0) {
          data.list.forEach((v) => {
            v.value = v.colorNo
          })
          this.restaurants = data.list
        }
      })
    },
    //根据产品品牌的Id获取工厂列表
    getFactoryList() {
      // if (this.dataForm.brandId != null || this.dataForm.brandId != "" || this.dataForm.brandId != "-1") {
      //   let params = {
      //     brandId: this.dataForm.brandId,
      //   };
      //   getFactory(params).then(({ data }) => {
      //     if (data && data.code === 0) {
      //       this.factoryList = data.list;
      //     } else {
      //       this.factoryList = [];
      //     }
      //   });
      // } else {
      //   this.dataForm.factoryId = '-1';
      // }
      factoryList().then(({ data }) => {
        if (data && data.code === 0) {
          this.factoryList = data.data
          // let vo = sessionStorage.getItem('factoryId')
          // this.dataForm.factoryId = vo ? vo : '-1'
          // 以上是当前工厂为默认工厂
          this.dataForm.brandId = data.brandId
        } else {
          this.factoryList = []
        }
      })
    },
    //混粉推荐
    mixpowder(row) {
      //searchData: this.searchData,转换成老虎的id
      var productSeries = ''
      var productSurface = ''
      var productIndustry = ''
      var productCategory = ''
      if (this.searchData) {
        for (const key in this.searchData) {
          if (key.endsWith('-1')) {
            //表面
            productSurface = this.searchData[key]
          }
          if (key.endsWith('-3')) {
            //系列
            productSeries = this.searchData[key]
          }
          if (key.endsWith('-5')) {
            //产品分类
            productCategory = this.searchData[key]
          }
          if (key.endsWith('-4')) {
            //应用行业
            productIndustry = this.searchData[key]
          }
        }
      }
      this.$router.push({
        path: '/mixpowderproduct',
        query: {
          factoryId: this.dataForm.factoryId,
          productSeries: productSeries,
          productSurface: productSurface,
          productIndustry: productIndustry,
          productCategory: productCategory,
          hasProductNum: this.dataForm.hasProductNum,
          //"glossValue": glossValue,
          lAve: this.Lvaule,
          aAve: this.Avaule,
          bAve: this.Bvaule
          //"productNum":row.productNum,
          //"sampleOrderNo":row.sampleOrderNo
        }
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 产品详情
    cpdetailsHandle(id, e00Value, e00lab2, brandId) {
      this.cpdetailsVisible = true
      this.$nextTick(() => {
        this.$refs.cpdetails.init(id, e00Value, e00lab2, brandId)
      })
    },
    //配方详情
    addRecipeHandle(id) {
      this.addRecipeVisible = true
      this.$nextTick(() => {
        this.$refs.addRecipe.init(id)
      })
    },
    //input框change事件
    inputTypeChange(value) {
      this.initDom()
      this.a = ''
      this.type = value
      if (value == '0') {
        this.s = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        this.color1 = ''
        this.labStr = ''
        this.hexStr = ''
        this.textStr = ''
        this.inputType = '0'
        this.deviceType = ''
        this.dataList = []
        this.totalPage = 0
      } else if (value == '1') {
        this.s = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        this.color1 = ''
        this.labStr = ''
        this.hexStr = ''
        this.textStr = ''
        this.inputType = '1'
        // this.colorNoType = "2";
        this.deviceType = ''
        this.dataList = []
        this.totalPage = 0
      } else if (value == '2') {
        this.color1 = '#1A2F44'
        this.labStr = ''
        this.hexStr = ''
        this.textStr = ''
        //this.getlabvalue();
        this.inputType = '2'
        this.dataList = []
        this.totalPage = 0
      } else {
        this.s = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        this.color1 = ''
        this.labStr = ''
        this.hexStr = ''
        this.textStr = ''
        this.inputType = '3'
        this.searchNoType = '1'
        this.dataList = []
        this.totalPage = 0
      }
      this.fuzzyMatching = false
      this.productNo = ''
      this.ralColorNo = ''
      this.dataForm.l1 = ''
      this.dataForm.a1 = ''
      this.dataForm.b1 = ''
      this.dataForm.l2 = ''
      this.dataForm.a2 = ''
      this.dataForm.b2 = ''
      this.dataForm.l3 = ''
      this.dataForm.a3 = ''
      this.dataForm.b3 = ''
      this.dataForm.lAve = ''
      this.dataForm.aAve = ''
      this.dataForm.bAve = ''
      this.closeReadMessage()
      this.resetOther()
    },
    // deviceTypeChange(value) {
    //   this.a = "";
    //   if (value == '1') {
    //     this.s = "";
    //     this.productNo = "";
    //     this.ralColorNo = "";
    //     this.color1 = "";
    //     this.Lvaule = "";
    //     this.Avaule = "";
    //     this.Bvaule = "";
    //     this.deviceType = "1";
    //     this.inputType = "0";
    //     this.colorNoType = "";
    //     this.dataList = [];
    //     this.totalPage = 0;
    //   } else if (value == '2') {
    //     this.s = "";
    //     this.productNo = "";
    //     this.ralColorNo = "";
    //     this.color1 = "";
    //     this.Lvaule = "";
    //     this.Avaule = "";
    //     this.Bvaule = "";
    //     this.deviceType = "2";
    //     this.inputType = "0";
    //     this.dataList = [];
    //     this.totalPage = 0;
    //     this.colorNoType = "";
    //   } else if (value == '3') {
    //     this.s = "";
    //     this.productNo = "";
    //     this.ralColorNo = "";
    //     this.color1 = "";
    //     this.Lvaule = "";
    //     this.Avaule = "";
    //     this.Bvaule = "";
    //     this.deviceType = "3";
    //     this.inputType = "0";
    //     this.dataList = [];
    //     this.totalPage = 0;
    //     this.colorNoType = "";
    //   } else if (value == '4') {
    //     this.s = "";
    //     this.productNo = "";
    //     this.ralColorNo = "";
    //     this.color1 = "";
    //     this.Lvaule = "";
    //     this.Avaule = "";
    //     this.Bvaule = "";
    //     this.deviceType = "4";
    //     this.inputType = "0";
    //     this.dataList = [];
    //     this.totalPage = 0;
    //     this.colorNoType = "";
    //   }
    // },
    colorNoTypeChange(value) {
      this.a = ''
      if (value == '1') {
        this.ralColorNo = ''
        this.productNo = ''
        this.color1 = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        // this.colorNoType = "1";
        this.inputType = '1'
        this.dataList = []
        this.totalPage = 0
      } else if (value == '2') {
        this.ralColorNo = ''
        this.productNo = ''
        this.color1 = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        // this.colorNoType = "2";
        this.inputType = '1'
        this.dataList = []
        this.totalPage = 0
      } else if (value == '3') {
        this.ralColorNo = ''
        this.productNo = ''
        this.color1 = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        // this.colorNoType = "3";
        this.inputType = '1'
        this.dataList = []
        this.totalPage = 0
      } else {
        this.ralColorNo = ''
        this.productNo = ''
        this.color1 = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        // this.colorNoType = "1";
        this.inputType = '1'
        this.dataList = []
        this.totalPage = 0
      }
      this.$refs['mycolorNoType'].suggestions = []
      this.getColorBookList()
    },
    searchNoTypeChange(value) {
      this.a = ''
      if (value == '1') {
        this.ralColorNo = ''
        this.productNo = ''
        this.color1 = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        this.searchNoType = '1'
        this.inputType = '3'
        this.dataList = []
        this.totalPage = 0
        this.dataForm.productSeries = '-1'
        this.dataForm.productNo = ''
      } else if (value == '2') {
        this.ralColorNo = ''
        this.productNo = ''
        this.color1 = ''
        this.Lvaule = ''
        this.Avaule = ''
        this.Bvaule = ''
        this.searchNoType = '2'
        this.inputType = '3'
        this.dataList = []
        this.totalPage = 0
      }
      this.resetOther()
    },
    resetOther() {
      this.historyId = undefined
      this.historyOpt = undefined
    },

    initWebSocket() {
      //初始化weosocket
      //ws地址
      if (this.websock) {
        this.websock.close()
      }
      var id = this.$store.getters.userDevice.id
      var wsuri = process.env.VUE_APP_WS_API + '/websocket/search_product_' + id
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onopen = this.websocketonopen
      //this.websock.onclose = this.websocketclose
    },
    websocketonmessage(e) {
      //数据接收
      //code:0.搜索产品,1.状态变更,2.连接成功
      //msg:
      //data:
      var retData = JSON.parse(e.data)
      var code = retData.code
      console.log(code)
      var msg = retData.msg
      console.log(msg)
      var data = retData.data
      console.log(data)
      if (code == 0) {
        this.Lvaule = data.lAve
        this.Avaule = data.aAve
        this.Bvaule = data.bAve
        this.dataForm.lAve = data.lAve
        this.dataForm.aAve = data.aAve
        this.dataForm.bAve = data.bAve
        this.dataForm.l1 = data.l1
        this.dataForm.a1 = data.a1
        this.dataForm.b1 = data.b1
        this.dataForm.l2 = data.l2
        this.dataForm.a2 = data.a2
        this.dataForm.b2 = data.b2
        this.dataForm.l3 = data.l3
        this.dataForm.a3 = data.a3
        this.dataForm.b3 = data.b3
        this.hexStr = data.hex
        this.labStr = this.Lvaule + ' ' + this.Avaule + ' ' + this.Bvaule + ' '
        this.setHexTextStr(this.hexStr)
        this.$message.success(this.$t('data_sync_success'))
        this.clearTimer() // 关闭定时器
        this.loading = false
        this.isdisabled = false
        this.websock.close()
      }
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      // let data = {
      //   code: 0,
      //   msg: '这是client：初次连接'
      // }
      // this.websocketsend(JSON.stringify(data))
    },
    websocketsend(agentData) {
      //数据发送
      this.websock.send(agentData)
    },
    websocketonerror() {
      return this.$message.error(this.$t('websocket_connection_failed'))
    },
    websocketclose(e) {
      //关闭
      this.websock.close()
      console.log('connection closed (' + e.code + ')')
    }
  }
}
</script>

<style lang="scss" scoped>
.app-product-container {
  padding: 15px;
  background-color: #eaf1fc;
  position: relative;
}
.logo {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 172px;
  img {
    width: 604px;
    height: 104px;
  }
}
.main {
  background: #fff;
  margin-top: 20px;
}
.c_top {
  padding-bottom: 10px;
  border-bottom: 1px dotted #e0e3ec;
}
.c_main {
  padding-left: 50px;
  padding-top: 40px;
  padding-bottom: 50px;
  background: #fff;
}
.c_t_inputcontent {
  margin-top: 50px;
}
.divTab {
  background: #fff;
  margin-top: 20px;
}
.content {
  background: #fff;
  padding: 5px;
}
.c_m_cards {
  justify-content: flex-start;
  // padding: 7.5px;
}
.c_m_c_img {
  padding: 10px;
  border-radius: 15px;
}
</style>
<style lang="scss">
.app-product-container {
  .el-radio-button:focus:not(.is-focus):not(:active) {
    -webkit-box-shadow: 0 0 2px 2px #fff !important;
    box-shadow: 0 0 2px 2px #fff !important;
  }
  .el-tabs__item.is-top {
    background: #f2f6fc;
    opacity: 1;
    border-radius: 4px;
  }
  .el-radio-button__inner {
    display: flex;
    align-items: center;
    border: 0;
    background: #e0e3ec;
    display: flex;
    justify-content: center;
    &.focus,
    &:focus,
    &:focus {
      border-color: #fff !important;
    }
    img {
      width: 19px;
      height: 19px;
      margin-right: 10px;
    }
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    border-color: #e0e3ec !important;
    box-shadow: none !important;
  }
  .el-radio-button {
    width: 220px;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    // background: url('../../assets/pro_tabs.png') no-repeat;
    border-radius: 10px 10px 0px 0px;
    background: white;
    color: #7073ff;
    border-color: #e0e3ec;
    box-shadow: none;
    border: 0;
    background-size: contain;
  }
  .el-tabs--card > .el-tabs__header {
    border: 0;
  }
  .el-tabs__nav.is-top {
    border: 0;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    background: #7073ff;
    color: #fff;
    position: relative;
    border-radius: 4px !important;
    &::before {
      content: '';
      width: 0px;
      height: 0px;
      border-top: 9px solid #7073ff;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      position: absolute;
      top: 38px;
      left: 60px;
    }
  }
  .el-tabs__nav-wrap,
  .el-tabs__nav-scroll {
    overflow: auto;
    padding-left: 17.5px;
  }
  .el-tabs__nav.is-top {
    height: 50px;
    div {
      &:nth-child(2) {
        background: #f2f6fc;
        margin-left: 15px;
        border: 0 !important;
        border-radius: 4px !important;
      }
    }
  }
  .el-tabs.el-tabs--card.el-tabs--top {
    height: 50px;
  }
  .el-card {
    position: relative;
  }
  .layout-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    //bottom: 50%;
    height: 160px;
    background: rgba(0, 0, 0, 0.32);
    opacity: 1;
    .main-mask {
      opacity: 1;
      position: absolute;
      display: flex;
      justify-content: center;
      bottom: 10px;
      left: 0;
      right: 0;
    }
  }
  // .el-card__body {
  //   cursor: pointer;
  // }
  .el-button.btn.el-button--primary.is-disabled {
    width: 153px;
    background: rgba(255, 176, 72, 0.09);
    border: 2px solid #ffab3d;
    opacity: 1;
    border-radius: 2px;
    color: #ffab3d;
    padding-left: 0;
    > span {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      img {
        position: absolute;
        top: -6px;
        right: -10px;
      }
    }
  }
  .el-input--suffix .el-input__inner {
    border-radius: 0;
  }
  .el-radio-group {
    // background: url("../../assets/pro_tabs1.png");
    // background-size: 100%;
    // background-position-x: 100px;
    // width: 100%;
  }
  .el-input-group {
    width: 226px;
  }
  .c_t_inputcontent {
    .el-button.btn.el-button--primary {
      padding: 0 15px;
      background: rgba(255, 176, 72, 0.09);
      border: 2px solid #ffab3d;
      opacity: 1;
      border-radius: 2px;
      color: #ffab3d;
      span {
        display: flex;
        align-items: center;
        img {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.divTab .el-tabs__nav.is-top {
  float: left;
}
.spanSize {
  font-size: 14px;
}
.app-product-container {
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: none !important;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: none !important;
  }
  .el-checkbox__label {
    font-size: 16px !important;
  }
}
#fenye {
  .number,
  .btn-quicknext {
    display: none;
  }
  .active {
    display: block;
  }
  .el-pagination {
    margin-top: 15px;
    text-align: right;
    margin-right: 50px;
  }
}
/* .el-icon-my-export{
    background: url(../../assets/yqt.png) center no-repeat;
    background-size: cover;
} */
.c_t_i_l_top .el-input__inner {
  border: 0;
  border-radius: 0;
  min-width: 128px;
  height: 37px;
  background: rgba(245, 245, 245, 0.6);
  text-align: center;
}

.read-message {
  font-size: 14px;
  background-color: #e1f8d8;
  border: 1px solid #bbdaab;
  padding: 8px;
  color: #67c23a;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  animation: reveal 0.2s;
  transform: translate3d(0, 0, 0);
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.color-card-item {
  display: inline-block;
  height: 24px;
  width: 24px;
  padding: 0px;
  margin-bottom: -5px;
  margin-right: 10px;
}

.c_m_cards .el-card {
  width: 260px;
  /*height: 240px;*/
  margin-left: 19px;
  margin-bottom: 20px;
}
.c_top .el-color-picker__trigger .el-icon-arrow-down:before {
  content: url('../../../src/assets/img/seBan2.png') !important;
  font-size: 18px;
}
.c_top .el-icon-close:before {
  content: url('../../../src/assets/img/seBan2.png') !important;
  font-size: 18px;
}
.c_top .el-color-picker__trigger {
  border: none;
  padding: 0;
  margin: -10px;
  height: 40px;
  width: 38px;
  background-color: cornflowerblue;
}
.c_top .el-color-picker__color {
  border: none !important;
}
.el-color-dropdown__btns button {
  display: none !important;
}
// .c_top .el-color-dropdown__link-btn {
//   display: none  !important;
// }
// .c_top .el-color-dropdown__btn {
//   display: none  !important;
// }
.el-input-group__prepend {
  color: #303133;
  vertical-align: middle;
  display: table-cell;
  border: 1px solid #dcdfe6;
  border-radius: 0px;
  width: 0px;
  white-space: nowrap;
  height: 40px;
  padding: 0px;
}
.w100 {
  width: 100px;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.c_top {
  text-align: center;
}
.c_t_title {
  font-size: 28px;
  color: #666666;
  margin-bottom: 10px;
}
.c_t_title span {
  color: #009cff;
}
.c_t_inputcontent {
  display: flex;
  justify-content: center;
}
.c_t_i_l_top {
  display: flex;
  justify-content: center;
  background: rgba(245, 245, 245, 0.6);
  height: 40px;
  border: 1px #e5e5e5 solid;
  align-items: center;
}
.c_t_i_l_top .color {
  width: 23px;
  height: 23px;
}
.c_tips {
  color: #999999;
  font-size: 12px;
}
.btn {
  height: 40px;
  border-radius: 2px;
  margin-left: 10px;
}
.btn-input {
  height: 40px;
  border-radius: 2px;
  margin-left: 10px;
}
.c_t_colorcontent {
  width: 315px;
  height: 115px;
  display: flex;
  float: left;
  align-items: self-start;
  color: #ffffff;
  font-size: 18px;
  padding: 14px;
  margin: 10px 0 10px 0px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  p {
    margin: 15px 0 0;
  }
  img {
    width: 28px;
    height: 28px;
  }
}
.c_t_color_history {
  float: left;
  margin-left: 30px;

  p {
    margin: 10px 0 0;
  }
  .success {
    font-size: 30px;
    font-weight: bold;
    color: #67c23a;
  }
}
.c_main {
  // border: 1px #f0d1d1 solid;
  padding: 15px 0;
  margin-top: 10px;
}
.c_m_c_img {
  width: 100%;
  height: 160px;
}
.c_m_c_img_ {
  width: calc(100% - 20px);
  height: 140px;
  margin: 10px;
  border-radius: 5px;
}
.c_m_cards {
  display: flex;
  flex-wrap: wrap;
  min-height: 240px;
}
.c_m_c_bot {
  color: #333333;
  font-size: 15px;
  padding: 0 10px;
}
.c_m_c_b_txt {
  font-size: 12px;
  p {
    overflow: normal;
  }
}
.c_m_c_b_txt span {
  color: #999999;
  width: calc(100% - 135px);
  display: inline-block;
  margin-right: 17px;
}
.c_t_radiocontent {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-box-align: self-start;
  -ms-flex-align: self-start;
  align-items: self-start;
  color: #ffffff;
  font-size: 18px;
  background: #e0e3ec;
  position: relative;
  height: 40px;
  overflow: hidden;
}
.evaluate-results {
  padding-top: 10px;
}
</style>
